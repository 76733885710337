import { useLocation } from "react-router-dom";
import { BottomTab, EventItem, ExploreFiltered, ExploreHeader, Footer, LocationModal, ModalLoader, SearchModal } from "../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useEffect, useRef, useState } from "react";
import { generalObj } from "../../constant/objects_types";
import { fetchAllEvent, fetchEventType } from "../../action/generalFunc";
import { useUserLocation } from "../../ticki_hooks";

const ExploreLanding = () => {
    const [locationData, locationStateData] = useUserLocation();
    const [locationModal, setLocationModal] = useState<boolean>(false);
    const [newLocation, setNewLocation] = useState<string | null>(null);

    const location = useLocation();
    const account = useSelector((state:RootState) => state.account);
    const [itemBlock, setItemBlock] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [events, setEvents] = useState<generalObj[]>([]);
    const [eventFilters, setEventFilter] = useState<generalObj[]>([]);
    const [selectedFilter, setSelectedFilter] = useState<generalObj | null>(null);
    const [exploreFiltered, setExploredFilter] = useState<boolean>(false);
    const headerRef = useRef<HTMLDivElement | null>(null);
    const [headerHeight, setHeaderHeight] = useState<number>(180);
    const [showingFilter, setShowingFilter] = useState<boolean>(false);
    const [openSearch, setOpenSearch] = useState<boolean>(false);
    const [pageMeta, setPageMeta] = useState<generalObj|null>(null);


    useEffect(() => {
        if (!account) return;
        setModalLoading(true);
        setItemBlock(false);
        const getEventTypes = async (events:generalObj) => {
            let new_filter:object[] = [];
            let current_event_filter:object[] = [];
            events.forEach((element:generalObj) => {
                if (!current_event_filter.includes(element.event_type_id)) {
                    current_event_filter.push(element.event_type_id);
                }
            })

            const result:generalObj = await fetchEventType(account.token);
            if (result.status) {
                result.data.data.forEach((element:generalObj) => {
                    new_filter.push({
                        ...element,
                        show:current_event_filter.includes(element.id) ? true : false,
                        active: false,
                        sub_filter: []
                    })
                });
            }
      
            setEventFilter(new_filter);
        }

        const getAllEvents = async () => {
            const result:generalObj = await fetchAllEvent(account.token, 1, 18);
            if (result.status) {
                let allEvents = result.data.data.filter((item:generalObj) => {
                    item.show = true;
                    return item;
                });
                setEvents(allEvents);
                getEventTypes(allEvents);
                setPageMeta(result.data.meta);
                return true;
            }else {
                return false;
            }
        }
      
        getAllEvents()
        .then((resp) => {
            if (resp) {
                setItemBlock(true);
                setModalLoading(false);
            }else {
                alert("unable to fetch events");
            }
            
        });

    }, [account]);

    useEffect(() => {
        if (!headerRef.current) return;
        setHeaderHeight(headerRef.current.clientHeight);
    }, [showingFilter]);

    const prepareFilter = (val:generalObj) => {
        setSelectedFilter({
            subFilters: val.sub_filter,
            title: val.name,
            events: events.filter((eItem:generalObj) => eItem.event_type_id === val.id)
        });
        setExploredFilter(true);
    }

    const loadData = async () => {
        if (!account) return;

        setModalLoading(true);
        let page = pageMeta && pageMeta.next_page ? pageMeta.next_page : 1;
        let result:generalObj = await fetchAllEvent(account.token, page, 18); 
        if (result.status) {
            let allEvents = result.data.data.filter((item:generalObj) => {
                item.show = true;
                return item;
            });
            // let updateTickets = result.data.data.filter((item:generalObj) => {
            //     if (item.used === 0) {
            //         item.show = true;
            //     }else {
            //         item.show = false;
            //     }
            //     item.user = item.user ? item.user : item.user_guest;
            //     item.ticket_info = item.ticket;
            //     return item;
            // });
            let update_event = [...events, ...allEvents];
            setEvents(update_event);
            setPageMeta(result.data.meta);
        }else {
            alert(result.message);
        }
        setModalLoading(false);
    }


    return (
        <>
            <div className={`appBody${!itemBlock ? " loading" : ""}`}>
                <div className="appCont" style={{position:'relative'}}>
                    <div ref={headerRef} className="explore__filter_header_container" style={{paddingTop:"20px", position:'fixed', width:'100%', top:"0px", zIndex:'99', backgroundColor:'#0D0404'}}>
                        <ExploreHeader eventFilters={eventFilters} locationData={locationData} newLocation={newLocation} openLocationModal={(val) => setLocationModal(val)} showDistance={(val) => setShowingFilter(val)} onPress={(val) => prepareFilter(val)} triggerSearch={() => setOpenSearch(true)} />
                    </div>
                    <div className="explore__event_container" style={{marginTop:`${headerHeight}px`}}>
                        <div>
                            {events.map((item:generalObj, index:number) => (
                                <EventItem key={index} item={item} listCont={{marginTop: index === 0 ? "20px" : "40px"}} actionPressed={(action, id)=>console.log(action, id)} updateEventItem={(id)=>console.log(id)} openRecommendPop={() => console.log('open recommend')}  />
                            ))}
                        </div>
                        {pageMeta && pageMeta.next_page && (
                            <button style={{display:'flex', flexDirection:'column', alignItems:'center', backgroundColor:"#353434", color: "#FFFFFF99", textAlign:'center', padding:"10px", margin: "20px auto"}} onClick={() => loadData()}>
                                Load more
                            </button>
                        )}
                    </div>
                </div>
                {exploreFiltered && selectedFilter && (
                    <ExploreFiltered visible={exploreFiltered} explore_item={selectedFilter} onModalClose={()=>{
                        setExploredFilter(false);
                        setSelectedFilter(null);
                    }} />
                )}
                
                <Footer />
            </div>
            <BottomTab location_path={location.pathname} />
            <ModalLoader visible={modalLoading} />

            {locationStateData && (
                <LocationModal visible={locationModal}  locationData={locationStateData} modalClose={() => setLocationModal(false)} newLocation={(val) => {setNewLocation(val); setLocationModal(false);}} />
            )}
            <SearchModal visible={openSearch} modalClose={() => setOpenSearch(false)} />
            
        </>
    )
}

export default ExploreLanding;