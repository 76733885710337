import { useState, useEffect, useRef } from 'react';
import { generalObj } from '../../constant/objects_types';
import { addPayAccount, getAccountName, getNgBanks, getPayAccounts } from '../../action/generalFunc';
import { BackArrow, ButtonFrame, InputField, PickerModal } from '../../components';
import { IoAdd } from 'react-icons/io5';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';

const PaymentAccount = () => {
    const [accSect, setAccSect] = useState<generalObj>({
        selectAcc: true,
        addAcc: false 
    });

    const [accountState, setAccountState] = useState<generalObj>({
        name:'',
        bank:{
            label:'',
            value:''
        },
        acNumber:'',
        bankCode:null,
        checkName: false,
        nameChecked: false
    });

    const accountTypeList = [
        {
            id:'00',
            label:'--Please select--',
            value:''
        },{
            id:'1',
            label:'Current Account',
            value:'Current'
        },{
            id:'2',
            label:'Savings Account',
            value:'Saving'
        }
    ];
    const [seletedAccountType, setSelectedAccountType] = useState<generalObj>({
        label:'--Please select--',
        value:''
    })
    
    const [bankList, setBankList] = useState<generalObj[]>([]);
    const [accountItem, setAccountItem] = useState<generalObj[]>([]);
    const [openPicker, setOpenPicker] = useState<boolean>(false);
    const [openAccPicker, setOpenAccPicker] = useState<boolean>(false);
    const accNumberRef = useRef<HTMLInputElement | null>(null);
    const accNameRef = useRef<HTMLInputElement | null>(null);

    const { setModalLoading }:{ setModalLoading:Function } = useOutletContext();
    const account = useSelector((state:RootState) => state.account);

    useEffect(() => {
        if (!account) return;

        (async () => {
            setModalLoading(true);
            let result:generalObj = await getPayAccounts(account.token);
            if (result.status) {
                let result_minimal:generalObj[] = [];
                result.data.data.forEach((item:generalObj, index:number)=> {
                    result_minimal.push({
                        id: item.id,
                        name:item.account_name,
                        bank:{
                            label:item.bank_name,
                            value:item.code
                        },
                        acNumber:item.account_number,
                        selected: index===0?true:false
                    })
                })
                setAccountItem(result_minimal);
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    useEffect(() => {
        const loadBank = async() => {
            let result = await getNgBanks();
            if (result.status) {
                let result_minimal:generalObj[] = [];
                result.data.forEach((item:generalObj, index:number)=> {
                    if (item.active && !item.is_deleted) {
                        if (index===0) {
                            setAccountState((prevAccountState) => ({...prevAccountState, bank:{label:item.name, value:item.code}}));
                        }
                        
                        result_minimal.push({
                            id:item.id,
                            label:item.name,
                            slug: item.slug,
                            value:item.code,
                            selected: index===0?true:false
                        })
                    }
                })
                setBankList(result_minimal);
            }
        }
        loadBank();
    }, [])

    useEffect(() => {
        if (accountState.acNumber.length !== 10 || !accountState.checkName || openPicker) return;
        const checkAccNumber = async () => {
            let result = await getAccountName(accountState.acNumber, accountState.bank.value);
            if (result.status) {
                setAccountState({...accountState, name:result.data.account_name, checkName:false, nameChecked:true});
            }
        }
        checkAccNumber();
    }, [accountState, openPicker]);

    const onAccountData = (evt:string, val:any) => {
        let new_data = {
            [evt]:val,
            nameChecked: false
        }
        if (evt === 'acNumber') {
            new_data['checkName'] = true
        }
        setAccountState({...accountState, ...new_data});
    }

    const selectAccount = (id:number | string) => {
        setAccountItem((prevAccountItem) => {
            return (
                prevAccountItem.filter((item) => {
                    if (item.id === id) {
                        item.selected = true;
                    }else {
                        item.selected = false;
                    }

                    return item;
                })
            )
        });
    }

    const addNewAccount = async () => {
        if (!account || !accountState.nameChecked || seletedAccountType.value === '') return;
        setModalLoading(true);
        let new_data = {
            id:`${Math.ceil(Math.random()*1000)}`,
            selected: true, 
            name:accountState.name,
            bank:{
                label:accountState.bank.label,
                value:accountState.bank.value
            },
            acNumber:accountState.acNumber,
            accType: seletedAccountType.value
        }
        const result:generalObj = await addPayAccount(account.token, {
            bank_name: new_data.bank.label,
            account_name: new_data.name,
            account_number: new_data.acNumber,
            account_type: new_data.accType,
            bank_code: new_data.bank.value
        });

        if (result.status) {
            let new_selection = accountItem.filter((item) => {
                item.selected = false
                return item
            });
            new_data.id = result.data.id;
            let ab_new_selection = [new_data, ...new_selection]
            setAccountItem(ab_new_selection);
            setAccSect({
                selectAcc: true,
                addAcc: false 
            });
            setAccountState({
                name:'',
                bank:{
                    label:'',
                    value:''
                },
                acNumber:'',
                checkName: false,
                nameChecked: false
            });
        }else {
            alert(result.message);
        }
        
        setModalLoading(false);
    }  

    return (
        <>
            <PickerModal visible={openPicker} pickerItem={bankList} selectedValue={accountState.bank.value} onChange={(val) => {setAccountState({...accountState, bank:{label:val.label, value:val.value}})}} modalClose={()=>setOpenPicker(false)} />
            <PickerModal visible={openAccPicker} pickerItem={accountTypeList} selectedValue={seletedAccountType.value} onChange={(val) => {setSelectedAccountType({label:val.label, value:val.value})}} modalClose={()=>setOpenAccPicker(false)} />
        
            <div className='profileView__container' style={{height:'100%', position:'relative'}}>
                <div style={{padding:"0px 20px"}}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"20px"}}>
                        <div style={{display:'flex', alignItems:'center', gap:"3px"}}>
                            <BackArrow onPress={() => accSect.selectAcc ? window.history.back() : setAccSect({selectAcc: true,addAcc: false })} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                            <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>{accSect.selectAcc ? 'Payment Account' : 'Add Payment Accounts' }</div>
                        </div>
                    </div>

                    <div style={{width:'100%', marginTop:"24px"}}>
                        {
                            accSect.selectAcc && (
                                <>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <div>
                                            <div className='inputLabel' style={{fontSize:"14px", marginTop:"8px"}}>Add the account you would like to receive payments from.</div>
                                        </div>
                                    </div>
                                    {accountItem && accountItem.length > 0 ? (
                                        <div style={{maxHeight:"300px", marginTop:"24px", overflowX:'hidden'}}>
                                            {accountItem.map((item, index) => (
                                                <button key={index} style={{padding: "16px", border:"1px solid rgba(255, 255, 255, 0.12)", backgroundColor: item.selected? '#DB012E':'transparent', display:'flex', flexDirection:'column', gap:"6px", borderRadius:"12px", marginTop:index === 0? 0: "24px", width:"100%"}} onClick={()=>selectAccount(item.id)}>
                                                    <div className='locationRegion'>{item.acNumber}</div>
                                                    <div className='locationRegion'>{item.name.toUpperCase()}</div>
                                                    <div className='locationRegion'>{item.bank.label}</div>
                                                </button>
                                            ))}
                                        </div>
                                    ): (
                                        <div style={{marginTop:"24px"}}>
                                            <div style={{padding: "16px", border:"1px solid rgba(255, 255, 255, 0.12)", backgroundColor: 'transparent', borderRadius:"12px", marginTop:"0px", display:'flex', alignItems:'center', marginBottom:"20px", justifyContent:'center'}}>
                                                <div className='locationRegion'>You haven't added an account</div>
                                            </div>
                                        </div>
                                    )}
                                    <button style={{display:'flex', marginBlock:"24px", marginTop:accountItem.length === 0 ? 0 :"24px"}} onClick={()=>setAccSect({selectAcc:false, addAcc:true})}>
                                        <div>
                                            <IoAdd size={"16px"} color={"#DB012E"} />
                                        </div>
                                        <div className='sectionTopLink' style={{fontSize:"13px", lineHeight:"16px"}}>Add Payment Account</div>
                                    </button>
                                </>
                            )
                        }

                        {   
                            accSect.addAcc && (
                                <>
                                    <div style={{display:'flex', justifyContent:'space-between'}}>
                                        <div>
                                            <div className='inputLabel' style={{fontSize:"14px", marginTop:"8px"}}>Add the payment details of the account you would like to receive payments from.</div>
                                        </div>
                                    </div>
                                    <div style={{marginTop:'14px'}}>
                                        <InputField refInput={(input) => accNumberRef.current =input} id="acc_no" label='Account Number' inputProp={{value:accountState.acNumber, type:'number', maxLength:10}} labelStyle={{color:"#FFF"}} customInputText={{backgroundColor:'rgba(255, 255, 255, 0.15)'}} onTextChange={(val) => onAccountData('acNumber', val)} />
                                        
                                        <div className='inputField'>
                                            <div className='inputLabel' style={{color:'#FFF'}}>Account Type</div>
                                            <div className='inputTextCont'>
                                                <button className='inputText' style={{ display:'flex', justifyContent:'flex-start', backgroundColor:'rgba(255, 255, 255, 0.15)'}} onClick={()=>setOpenAccPicker(true)}>
                                                    <span className='activeText'>{seletedAccountType.label}</span>
                                                </button>
                                            </div>
                                        </div>

                                        <div className='inputField'>
                                            <div className='inputLabel' style={{color:'#FFF'}}>Account Provider</div>
                                            <div className='inputTextCont'>
                                                <button className='inputText' style={{ display:'flex', justifyContent:'flex-start', backgroundColor:'rgba(255, 255, 255, 0.15)'}} onClick={()=>setOpenPicker(true)}>
                                                    <span className='activeText'>{accountState.bank.label}</span>
                                                </button>
                                            </div>
                                        </div>

                                        <InputField refInput={(input) => accNameRef.current =input} label='Account Name' id='acc_name' inputProp={{value:accountState.name, readOnly:true}} labelStyle={{color:'#FFF'}} customInputText={{backgroundColor:'rgba(255, 255, 255, 0.15)'}} onTextChange={(val) => val} />
                                    </div>
                                    
                                    <div style={{marginTop:"20px"}}>
                                        <ButtonFrame title="Done" contStyle={{marginTop: 0, border:"none", backgroundColor:'#DB012E', opacity:accountState.nameChecked?"1": "0.5"}} onPress={addNewAccount} />
                                    </div>
                                </>
                            )
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default PaymentAccount;