import { useState, useEffect } from "react";
import { generalObj } from "../../constant/objects_types";
import BackArrow from "../BackArrow";
import ListProfile from "./ListProfile";
import { IoChevronForwardOutline } from "react-icons/io5";
import NotifyEdit from "./NotifyEdit";

const NotificationInfoList = ({visible, profileInfoItem, modalClose}:{
    visible:boolean, 
    profileInfoItem: generalObj, 
    modalClose: () => void
}) => {
    const profileInfo:generalObj = profileInfoItem;
    const [profileData, setProfileData] = useState<generalObj>({
        events:{
            key:'events',
            title:'Events',
            options: [
                {
                    id: '1',
                    title: 'Event Recommendations',
                    description: 'Receive push notifications on upcoming recommended events.',
                    slug: "event_recommendation",
                    active: profileInfo.event_recommendation === 1 ? true : false
                },
                {
                    id: '2',
                    title: 'Event Reminders',
                    description: 'Receive push notifications reminding you of the event you’ll be attending 30 minutes before it starts.',
                    slug: "event_reminders",
                    active: profileInfo.event_reminders === 1 ? true : false
                },
                {
                    id: '3',
                    title: 'Cancelled Events',
                    description: 'Receive push notifications informing you when an event you will be attending has been cancelled.',
                    slug: "event_cancellation",
                    active: profileInfo.event_cancellation === 1 ? true : false
                }
            ]
        },
        tickets:{
            key:'tickets',
            title:'Tickets',
            options: [
                {
                    id: '1',
                    title: 'Ticket Purchases/Refund',
                    description: 'Receive push notifications when you have successfully purchased a ticket or when you get a refund.',
                    slug: "ticket_purchase",
                    active: profileInfo.ticket_purchase === 1 ? true : false
                },
                {
                    id: '2',
                    title: 'Ticket Sales',
                    description: 'Receive push notifications when someone buys a ticket for your event.',
                    slug: "ticket_sales",
                    active: profileInfo.ticket_sales === 1 ? true : false
                },
                {
                    id: '3',
                    title: 'Check in',
                    description: 'Receive push notifications when your ticket has been checked into event.',
                    slug: "ticket_check_in",
                    active: profileInfo.ticket_check_in === 1 ? true : false
                }
            ]
        }
    });

    const [firstShow, setFirstShow] = useState<boolean>(false);

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editData, setEditData] = useState<generalObj | null>(null);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const updateProfileData = (pkey:string, value:any, valuekey:string="options") => {
        const Kdata = profileData[pkey]; 
        const new_option = Kdata.options.filter((kItem:generalObj) => {
            if (kItem.id === value) {
                kItem.active = !kItem.active;
            }
            return kItem;
        })
        const updated_keyData = {...Kdata, [valuekey]: new_option};
        setProfileData({...profileData, [pkey]:updated_keyData});
        
        if (editData && editData.key === pkey) {
            setEditData(updated_keyData);
        }
    }

    const setDataForEdit = (key:string) => {
        setEditData(profileData[key]);
        setOpenEdit(true);
    }

    const clearEditData = () => {
        setOpenEdit(false);
        setTimeout(() => {
            setEditData(null);
        }, 1000);
    }
    
    return (
        <>
            {firstShow && (
                <>
                    <div className={`notify__info modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                        <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Notification Information</div>
                                    </div>
                                </div>
                                
                                <div style={{width:'100%', marginTop:"24px", height:"100%"}}>
                                    <div style={{height:"calc(100% - 270px)", paddingBottom:'90px', overflowX:'hidden'}}>
                                        <ListProfile label="" value={profileData.events.title} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} onPress={()=>setDataForEdit(profileData.events.key)} />
                                        <ListProfile label="" value={profileData.tickets.title} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:24}} onPress={()=>setDataForEdit(profileData.tickets.key)} />
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {editData && (<NotifyEdit 
                visible={openEdit} 
                item={editData} 
                onTextChange={(key, id)=>{
                    updateProfileData(key, id);
                    //clearEditData();
                }} 
                modalClose={clearEditData} 
            />)}
        </>
    )
}

export default NotificationInfoList;