import { useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { UrlType, generalObj } from "../../constant/objects_types";
import { ButtonFrame, Footer, ModalLoader, NavBar, NewResellContact, NewResellDetail, NewResellImage, ReviewResell, SearchModal } from "../../components";
import { fetchEventSubCategory, fetchEventType, fetchResaleTicketsById, getCountryfromCode, getLocation, getPhoneCode, navigateRoute, setUpPrevUrl, validateEmail } from "../../action/generalFunc";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

const ResellEvent = () => {

    const [eventField, setEventField] = useState<generalObj[] | null>([
        {
            id:'1',
            title:'Ticket Details',
            isFilled: true,
            active:true,
            fields: {
                name: '',
                eventType: null,
                eventSub: null,
                performers:[],
                price:"",
                oneTime: {
                    startDate: '',
                    endDate: ''
                },
                tags: []
            }
        },
        {
            id:'2',
            title: 'Image',
            isFilled: false,
            active:false,
            fields: {
                coverImage: null,
                coverImageUrl: ""
            }
        },
        {
            id:'3',
            title:'Contact Info',
            isFilled: false,
            active:false,
            fields: {
                email:"",
                information: "",
                phone: {
                    label:"",
                    value: '',
                },
                phone_number: ""
            }
        }
    ]);
    const [eventError, setEventError] = useState({})
    const [modalLoading, setModalLoading] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);

    // const [filledTicket, setFilledTicket] = useState<boolean>(true);
    const [eventEditting, setEventEditting] = useState<boolean>(false);
    const [reviewObj, setReviewObj] = useState<generalObj[]>([]);
    const [openReview, setOpenReview] = useState<boolean>(false);

    const parentRef = useRef<HTMLDivElement | null>(null);
    const scrollRef = useRef<HTMLDivElement | null>(null);

    const { ticketId } = useParams();
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();

    useEffect(() => {
        if (account && account.isAuthenticated) return;
        navigate(setUpPrevUrl(window.location.pathname, `${UrlType.LOGIN}?redirect=${UrlType.CREATE_RESELL_EVENT}`))
    }, [account, navigate]);

    useEffect(() => {
        if (!account || !account.isAuthenticated) return;

        (async () => {
            const location = await getLocation();
            let pCode:generalObj[] = await getPhoneCode(location.countryCode);
            updateEventFields('3', "phone", {
                id:pCode[0].id,
                label:`${pCode[0].flag} ${pCode[0].phone_code}`,
                value:pCode[0].phone_code
            })
        })();
        setEventEditting(false);
    }, [account]);

    useEffect(() => {
        if (!ticketId || !account || !account.isAuthenticated) return;
        setModalLoading(true);
        const fetchevtType = async (eventItem:generalObj) => {
            const type_result:generalObj = await fetchEventType(account.token);
            if (type_result.status) {
                let evtType = {};
                type_result.data.data.forEach((element:generalObj) => {
                    if (element.id === eventItem.event_type_id) {
                        evtType = {
                            id: `${element.id}`,
                            label: element.name,
                            value: element.name
                        };
                    }
                });
                return evtType;
            }else {
                return null;
            }
        }
        const fetchevtSubType = async (eventItem:generalObj) => {
            const sub_result:generalObj = await fetchEventSubCategory(account.token);
            if (sub_result.status) {
                let evtType = {};
                sub_result.data.data.forEach((element:generalObj) => {
                    if (element.id === eventItem.event_sub_type_id) {
                        evtType = {
                            id: `${element.id}`,
                            parent_id: `${element.event_type_id}`,
                            label: element.name,
                            value: element.name,
                            show: true
                        };
                    }
                });
                return evtType;
            }else {
                return null;
            }
        }
        const formatNumber = (eventItem:generalObj) => {
            let pCode:generalObj = getCountryfromCode(eventItem.contact_number.replaceAll("+", "").split("-")[0]);
            return ({
                id:pCode.id,
                label:`${pCode.flag} ${pCode.phone_code}`,
                value:pCode.phone_code
            })
        }

        (async () => {
            const result:generalObj = await fetchResaleTicketsById(ticketId, account.token);
            if (!result.error) {
                const eventItem = result.data;
                          
                const titcket_editting = {
                    id:'1',
                    title:'Ticket Details',
                    isFilled: true,
                    active:true,
                    fields: {
                        name: eventItem.ticket_name,
                        eventType: await fetchevtType(eventItem),
                        eventSub: await fetchevtSubType(eventItem),
                        performers:eventItem.performers.length > 0 ? eventItem.performers.split(",") : [],
                        price: `${eventItem.price}`,
                        oneTime: {
                            startDate: new Date(`${eventItem.start_date}`),
                            endDate: new Date(`${eventItem.end_date}`)
                        },
                        tags: eventItem.tags.length > 0 ? eventItem.tags.split(",") : []
                    }
                }
                const image_editting = {
                    id:'2',
                    title: 'Image',
                    isFilled: true,
                    active:false,
                    fields: {
                        coverImage: null,
                        coverImageUrl: eventItem.image_url
                    }
                }
                const contact_editting = {
                    id:'3',
                    title:'Contact Info',
                    isFilled: true,
                    active:false,
                    fields: {
                        email:eventItem.contact_email,
                        information: eventItem.information,
                        phone: formatNumber(eventItem),
                        phone_number: eventItem.contact_number.split("-")[1]
                    }
                }
                console.log(titcket_editting);
                setEventField([titcket_editting, image_editting, contact_editting]);
                setEventEditting(true);
            }else {
                setEventEditting(false);
            }
            setModalLoading(false);
        })();
    }, [account, ticketId]);

    useEffect(() => {
        if (!eventField) return;
        setReviewObj((prevReviewObj) => {
            return (
                prevReviewObj.filter((rItem:generalObj) => {
                    if (rItem.id === 3) {
                        rItem.fields = eventField[2].fields
                    }
                    return rItem
                })
            )
        })
    }, [eventField]);

    const callValidateEvent = (nEvent:generalObj) => {
        let temp_error:generalObj = eventError;
        if (nEvent.fields.name.length < 5) {
            temp_error.name = true;
        }else {
            temp_error.name = false;
        }

        if (!nEvent.fields.eventType || nEvent.fields.eventType.label === '--Please select--') {
            temp_error.eventType = true;
        }else {
            temp_error.eventType = false;
        }

        if (!nEvent.fields.eventSub || nEvent.fields.eventSub.label === '--Please select--') {
            temp_error.eventSub = true;
        }else {
            temp_error.eventSub = false;
        }

        if (nEvent.fields.price === "") {
            temp_error.price = true;
        }else {
            temp_error.price = false;
        }

        if (nEvent.fields.oneTime.startDate === '') {
            temp_error.onceStartDate = true;
        }else {
            temp_error.onceStartDate = false;
        }

        if (nEvent.fields.oneTime.endDate === '') {
            temp_error.onceEndDate = true;
        }else {
            temp_error.onceEndDate = false;
        }

        setEventError({...temp_error});
        
        if (Object.entries(eventError).filter((item) => item[1]).length > 0){
            alert('Error Found');
            return false;
        }else {
            return true;
        }
    }

    const callValidateContact = (nContact:generalObj) => {
        let temp_error:generalObj = eventError;
        if (nContact.fields.phone_number === "" || nContact.fields.phone_number.length < 10) {
            temp_error.phone_number = true;
        }else {
            temp_error.phone_number = false;
        }

        if (nContact.fields.email === "" || !validateEmail(nContact.fields.email)) {
            temp_error.email = true;
        }else {
            temp_error.email = false;
        }


        if (nContact.fields.information.length < 15) {
            temp_error.information = true;
        }else {
            temp_error.information = false;
        }

        setEventError({...temp_error});
        
        if (Object.entries(eventError).filter((item) => item[1]).length > 0){
            alert('Error Found');
            return false;
        }else {
            return true;
        }
    }

    const switchTab = (currentId:string | number, nextTab:number, isRev:boolean = false) => {
        if (!scrollRef || !scrollRef.current) return;
        setEventField((prevEventField) => {
            if (!prevEventField) return null;
            return (
                prevEventField.filter((item, index) => {
                    if (item.id === currentId) {
                        item.isFilled = eventEditting && isRev? true : isRev ? false : true;
                        item.active = false
                    }

                    if (index === nextTab) {
                        item.active = true
                        item.isFilled = true
                    }
                    return item
                })
            )
        })

        // const next_tab = scrollRef.current.querySelector(`#event_tab_${nextTab}`);

        // next_tab?.scrollIntoView({behavior:'smooth'});
    }

    const promotionAssetsUpload = async (item:generalObj, nextTab:number) => {
        if (!item.fields.coverImage && !eventEditting) return;
        let imageErr = false;
        setModalLoading(true);
        if (imageErr) {
            alert('unable to upload image');
        }else {
            setModalLoading(false);
            switchTab(item.id, nextTab);
        }
        setModalLoading(false);
    }

    const updateEventFields = (sectionId:string | number, fieldName:string, fieldSol:any) => {
        setEventField((prevEventField) => {
            if (!prevEventField) return null;
            return (
                prevEventField.filter((item) => {
                    if (item.id === sectionId) {
                        item.fields[fieldName] = fieldSol 
                    }
                    return item
                })
            )
        });
    }

    return (
        <>
            <NavBar updateOpenSearchModal={(val) => setOpenSearch(val)} />
            <div className={`appBody create__event loading`} style={{position:'relative'}} ref={parentRef}>
                
                <div style={{height:'100%'}}>
                    <div className="create__event__container" style={{padding:"0px 20px", height:"calc(100% - 80px)", paddingTop:"20px"}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                            <div style={{flexDirection:'row', alignItems:'center'}}>
                                <span className="sectionTitle">Resell Ticket</span>
                            </div>
                            <button className="closeIcon" onClick={() =>  navigate(navigateRoute())}>
                                <IoCloseOutline size={"26px"} color="#FFF" />
                            </button>
                        </div>

                        <div style={{marginTop:"40px"}}>
                            <div className="tabbedPanel">
                                {eventField && eventField.map((item:generalObj, index:number) => (
                                    <div key={item.id}>
                                        <div className={`tabbedBorder${index === 0 ? " tabbedEdgeStart" : ""}${index === eventField.length - 1 ? " tabbedEdgeEnd" : ""}${item.isFilled ? " activeBorder" : ""}`}></div>
                                        <div className={`tabbedText bodyText${item.isFilled ? " activeText" : ""}`}>{item.title}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="tabbedPanelContent" ref={scrollRef}>
                            {eventField && eventField.map((item:generalObj, index:number) => (
                                item.active && (
                                    <div key={item.id} className="tabbedPanelContent__item" id={`event_tab_${index}`}>
                                        {item.id === "1" && <NewResellDetail item={item} editting={eventEditting} isError={eventError} onChange={(name, sol)=>updateEventFields(item.id, name, sol)} scrollRef={scrollRef} />}
                                        {item.id === "3" && <NewResellContact item={item} editting={eventEditting} isError={eventError} onChange={(name, sol)=>updateEventFields(item.id, name, sol)} />}
                                        {item.id === "2" && <NewResellImage itemuri={item} onChange={(name, sol)=>updateEventFields(item.id, name, sol)} parentObj={parentRef} />}
                                    </div>
                                )
                            ))}
                        </div>
                    </div>

                    {eventField && eventField.map((item, index) => (
                        <div key={item.id} className="create_event__action"  style={{display:'flex', paddingTop:0, marginBottom:"10px", marginInline:"20px"}}>
                            {item.id === "1" && item.active && <ButtonFrame title="Next" contStyle={{backgroundColor:'#DB012E', border:"none",marginTop: 0, opacity:"1"}} onPress={() => {
                                if (callValidateEvent(item)) {
                                    switchTab(item.id, index+1);
                                }
                            }} />}
                            {item.id === "3" && item.active && (
                                <div style={{display:'flex', marginBottom:"10px", paddingTop:0, gap:"8px", width:"100%"}}>
                                    <ButtonFrame title="Back" contStyle={{backgroundColor:'rgba(255, 255, 255, 0.16)', marginTop: 0, width:"100%", border:"none"}} onPress={() => switchTab(item.id, index-1, true)} />
                                    <ButtonFrame title="Next" contStyle={{backgroundColor:'#DB012E', border:"none",marginTop: 0, opacity:"1"}} onPress={() => {
                                        if (callValidateContact(item)) {
                                            setReviewObj([
                                                {
                                                    id:'1',
                                                    fields: eventField[0].fields
                                                },
                                                {
                                                    id:'2',
                                                    fields: eventField[1].fields
                                                },{
                                                    id:'3',
                                                    fields:eventField[2].fields
                                                }
                                            ])
                                            setOpenReview(true);
                                        }
                                    }
                                    } />
                                </div>
                            )}
                            {item.id === "2" && item.active && (
                                <div style={{display:'flex', marginBottom:"10px", paddingTop:0, gap:"8px", width:"100%"}}>
                                    <ButtonFrame title="Back" contStyle={{backgroundColor:'rgba(255, 255, 255, 0.16)', marginTop: 0, width:"100%", border:"none"}} onPress={() => switchTab(item.id, index-1, true)} />
                                    <ButtonFrame title="Next" contStyle={{backgroundColor:'#DB012E', border:"none",marginTop: 0, opacity:item.fields.coverImage || item.fields.coverImageUrl? "1" : "0.5"}} onPress={() => promotionAssetsUpload(item, index+1)} />
                                </div>
                            )}
                        </div>
                    ))}

                </div>
                    
                <Footer />
            </div>
            <ModalLoader visible={modalLoading} />
            <ReviewResell visible={openReview} eventObj={reviewObj} parentRef={parentRef} isEditting={eventEditting} modalClose={() => setOpenReview(false)} />

            <SearchModal visible={openSearch} modalClose={() => setOpenSearch(false)} />
        </>
    )
}

export default ResellEvent;