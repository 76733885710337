import { useEffect, useRef, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { UrlType, generalObj } from "../../constant/objects_types";
import { ButtonFrame, Footer, ModalLoader, NavBar, NewEvent, NewEventPromotion, NewEventTicket, SearchModal } from "../../components";
import { deriveInitialPrice, fetchEventSubCategory, fetchEventType, fetchEventUser, getUTCStr, navigateRoute, setUpPrevUrl, validateUrl } from "../../action/generalFunc";
import ReviewEvent from "../../components/createevent/ReviewEvent";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";

const CreateEvent = () => {

    const [eventField, setEventField] = useState<generalObj[] | null>([
        {
            id:'1',
            title:'Events',
            isFilled: true,
            active:true,
            fields: {
                name: '',
                description: '',
                eventType: null,
                eventSub: null,
                performers:[],
                location: {
                    online: true,
                    venue: false
                },
                online_location_link: '',
                venue_location:{
                    addressLabel: "",
                    coordinates: {},
                    formattedAddress: ""
                },
                visibility: {
                    public: true,
                    private: false
                },
                private_event_link: "",
                date: {
                    oneTime: true,
                    recurring: false
                },
                oneTime: {
                    startDate: '',
                    endDate: ''
                },
                recurring: {
                    startDate:'',
                    occurence:null,
                    withEndDate: true,
                    withEndOccurence: false,
                    endDate: '',
                    endOccurence:''
                },
                tags: []
            }
        },
        {
            id:'2',
            title: 'Promotions',
            isFilled: false,
            active:false,
            fields: {
                coverImage: null,
                promotionVideo: null,
                coverImageUrl: "",
                promotionVideoUrl: ""
            }
        },
        {
            id:'3',
            title:'Ticket',
            isFilled: false,
            active:false,
            fields: {
                numTickets:0,
                eventHasTicket: true,
                ticketCategory: false,
                UsersIncurCharge: true,
                noCategory: {
                    price:"",
                    symbol: '₦',
                    description: '',
                    ticketNum:"",
                    title: 'Regular',
                    slug: 'regular'
                },
                categories: [
                    {
                        id:'1',
                        title: 'V.V.I.P',
                        slug:'vvip',
                        price:"",
                        symbol: '₦',
                        description: '',
                        ticketNum:"",
                        isDeleted: false
                    },{
                        id:'2',
                        title: 'V.I.P',
                        slug:'vip',
                        price:"",
                        symbol: '₦',
                        description: '',
                        ticketNum:"",
                        isDeleted: false
                    },{
                        id:'3',
                        title: 'Regular',
                        slug:'regular',
                        price:"",
                        symbol: '₦',
                        description: '',
                        ticketNum:"",
                        isDeleted: false
                    }
                ],
                canScan: null,
                price: 0.00,
                serviceFee: 300,
                processFee: 300
            }
        }
    ]);
    const [openSearch, setOpenSearch] = useState(false);
    const [eventError, setEventError] = useState({})
    const [modalLoading, setModalLoading] = useState(false);

    const [eventEditting, setEventEditting] = useState<boolean>(false);
    const [reviewObj, setReviewObj] = useState<generalObj[]>([]);
    const [openReview, setOpenReview] = useState<boolean>(false);

    const parentRef = useRef<HTMLDivElement | null>(null);
    const scrollRef = useRef<HTMLDivElement | null>(null);

    const { eventId } = useParams();
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();

    useEffect(() => {
        if (account && account.isAuthenticated) return;
    
        navigate(setUpPrevUrl(window.location.pathname, `${UrlType.LOGIN}?redirect=${UrlType.CREATE_EVENT}`))
    }, [account, navigate]);

    useEffect(() => {
        if (!account || !account.isAuthenticated || !eventId) return;
        setModalLoading(true);
        const fetchevtType = async (eventItem:generalObj) => {
            const type_result:generalObj = await fetchEventType(account.token);
            if (type_result.status) {
                let evtType = {};
                type_result.data.data.forEach((element:generalObj) => {
                    if (element.id === eventItem.event_type_id) {
                        evtType = {
                            id: `${element.id}`,
                            label: element.name,
                            value: element.name
                        };
                    }
                });
                return evtType;
            }else {
                return null;
            }
        }
        const fetchevtSubType = async (eventItem:generalObj) => {
            const sub_result:generalObj = await fetchEventSubCategory(account.token);
            if (sub_result.status) {
                let evtType = {};
                sub_result.data.data.forEach((element:generalObj) => {
                    if (element.id === eventItem.event_category_id) {
                        evtType = {
                            id: `${element.id}`,
                            parent_id: `${element.event_type_id}`,
                            label: element.name,
                            value: element.name,
                            show: true
                        };
                    }
                });
                return evtType;
            }else {
                return null;
            }
        }
        const formatLocation = (eventItem:generalObj) => {
            if (eventItem.location === '') {
                return ({
                    addressLabel: "",
                    coordinates: {},
                    formattedAddress: ""
                })
            }
            let returnData:generalObj = {
                addressLabel: "",
                coordinates: {},
                formattedAddress: ""
            };

            const location_split:Array<string> = eventItem.location.split('[&]');
            location_split.forEach((item) => {
                let new_split:Array<string> = item.split('=');
                if (new_split[0] === "addressLabel") {
                    returnData.addressLabel = new_split[1];
                    returnData.formattedAddress = new_split[1];
                }
                if (new_split[0] === "coordinates") {
                    returnData.coordinates = {
                        latitude: parseFloat(new_split[1].split(',')[1]), 
                        longitude: parseFloat(new_split[1].split(',')[1])
                    };
                }

                if (new_split[0] === "formattedAddress") {
                    returnData.formattedAddress = new_split[1];
                }
            });

            return returnData;
        }

        const ticketCategoryPrep = (eventItem:generalObj) => {
            let ticketCat:generalObj[] = [];
            if (eventItem.ticket_has_category === 1) {
                eventItem.tickets.forEach((element:generalObj) => {
                    let new_obj:generalObj = {
                        id:element.id,
                        price:eventItem.charge_user === 1 ? `${deriveInitialPrice(parseFloat(element.price), true)}` : `${parseFloat(element.price)}`,
                        symbol: '₦',
                        description: element.description ? element.description : '',
                        ticketNum:element.quantity.toString(),
                        canNotDelete: element.sold ? true : false,
                        isDeleted: false
                    };

                    if (element.category === 'vvip') {
                        new_obj['title'] = 'V.V.I.P';
                        new_obj['slug'] = 'vvip';
                    }else if (element.category === 'vip') {
                        new_obj['title'] = 'V.I.P';
                        new_obj['slug'] = 'vip';
                    }else if (element.category === 'regular') {
                        new_obj['title'] = 'Regular';
                        new_obj['slug'] = 'regular';
                    }else {
                        new_obj['title'] = element.title;
                        new_obj['slug'] = element.category;
                    }

                    ticketCat.push(new_obj);
                });
                return ticketCat;
            }else {
                return ([
                    {
                        id:'1',
                        title: 'V.V.I.P',
                        slug:'vvip',
                        price:"",
                        symbol: '₦',
                        description: '',
                        ticketNum:"",
                        isDeleted: false
                    },{
                        id:'2',
                        title: 'V.I.P',
                        slug:'vip',
                        price:"",
                        symbol: '₦',
                        description: '',
                        ticketNum:"",
                        isDeleted: false
                    },{
                        id:'3',
                        title: 'Regular',
                        slug:'regular',
                        price:"",
                        symbol: '₦',
                        description: '',
                        ticketNum:"",
                        isDeleted: false
                    }
                ])
            }
        }  

        (async () => {
            const result:generalObj = await fetchEventUser(account.token, eventId);
            if (result.status) {
                const eventItem = result.data.data.event;
                const event_editting = {
                    id:'1',
                    title:'Events',
                    isFilled: true,
                    active:true,
                    fields: {
                        name: eventItem.name,
                        description: eventItem.description,
                        eventType: await fetchevtType(eventItem),
                        eventSub: await fetchevtSubType(eventItem),
                        performers:[],
                        location: {
                            online: eventItem.location_type === "online" ? true : false,
                            venue: eventItem.location_type === "venue" ? true : false,
                        },
                        online_location_link: eventItem.online_link,
                        venue_location:formatLocation(eventItem),
                        visibility: {
                            public: eventItem.visibility === 'public' ? true : false,
                            private: eventItem.visibility === 'private' ? true : false
                        },
                        private_event_link: "",
                        date: {
                            oneTime: eventItem.date_type === "one-time" ? true : false,
                            recurring: eventItem.date_type === "recurring" ? true : false
                        },
                        oneTime: {
                            startDate: eventItem.date_type === "one-time" ? new Date(getUTCStr(eventItem.start_date)) : "",
                            endDate: eventItem.date_type === "one-time" ? new Date(getUTCStr(eventItem.end_date)) : ""
                        },
                        recurring: {
                            startDate:new Date(getUTCStr(eventItem.start_date)),
                            occurence: {
                                label: eventItem.occurence,
                                value: eventItem.occurence
                            },
                            withEndDate: eventItem.end_type === "end_date" ? true : false,
                            withEndOccurence: eventItem.end_type === "occurence" ? true : false,
                            endDate: eventItem.end_type === "end_date" ? new Date(getUTCStr(eventItem.end_date)) : '',
                            endOccurence:eventItem.end_type === "occurence" ? eventItem.frequency : ''
                        },
                        tags: eventItem.tags
                    }
                }
                const promotional_editting = {
                    id:'2',
                    title: 'Promotions',
                    isFilled: true,
                    active:false,
                    fields: {
                        coverImage: null,
                        promotionVideo: null,
                        coverImageUrl: eventItem.cover_image,
                        promotionVideoUrl: eventItem.promotion_video
                    }
                }
                
                const ticket_editting = {
                    id:'3',
                    title:'Ticket',
                    isFilled: true,
                    active:false,
                    fields: {
                        numTickets:0,
                        eventHasTicket: eventItem.has_ticket === 1 ?true:false,
                        ticketCategory: eventItem.ticket_has_category === 1 ? true : false,
                        UsersIncurCharge: eventItem.charge_user === 1 ? true : false,
                        noCategory: eventItem.has_ticket === 1 && (eventItem.ticket_has_category === 0 && eventItem.tickets.length > 0) ? {
                            id: eventItem.tickets[0].id,
                            price:eventItem.charge_user === 1 ? `${deriveInitialPrice(parseFloat(eventItem.tickets[0].price), true)}` : `${parseFloat(eventItem.tickets[0].price)}`,
                            symbol: '₦',
                            description: eventItem.tickets[0].description ? eventItem.tickets[0].description : '',
                            ticketNum:eventItem.tickets[0].quantity.toString(),
                            title: eventItem.tickets[0].title? eventItem.tickets[0].title : 'Regular',
                            slug: eventItem.tickets[0].category
                        }:{
                            price:"",
                            symbol: '₦',
                            description: '',
                            ticketNum:"",
                            title: 'Regular',
                            slug: 'regular'
                        },
                        categories: ticketCategoryPrep(eventItem),
                        canScan: eventItem.authorized_scanners_user_data,
                        price: 0.00,
                        serviceFee: 300,
                        processFee: 300
                    }
                }
                setEventField([event_editting, promotional_editting, ticket_editting]);
                setEventEditting(true);
            }else {
                setEventEditting(false);
            }
            setModalLoading(false);
        })();
    }, [account, eventId]);

    useEffect(() => {
        if (!eventField) return;
        setReviewObj((prevReviewObj) => {
            return (
                prevReviewObj.filter((rItem:generalObj) => {
                    if (rItem.id === 3) {
                        rItem.fields = eventField[2].fields
                    }
                    return rItem
                })
            )
        })
    }, [eventField]);

    const callValidateEvent = (nEvent:generalObj) => {
        let temp_error:generalObj = eventError;
        if (nEvent.fields.name.length < 5) {
            temp_error.name = true;
        }else {
            temp_error.name = false;
        }

        if (nEvent.fields.description.length < 20) {
            temp_error.description = true;
        }else {
            temp_error.description = false;
        }

        if (!nEvent.fields.eventType || nEvent.fields.eventType.label === '--Please select--') {
            temp_error.eventType = true;
        }else {
            temp_error.eventType = false;
        }

        if (nEvent.fields.location.online && !validateUrl(nEvent.fields.online_location_link)) {
            temp_error.location_link = true;
        }else {
            temp_error.location_link = false;
        }


        if (nEvent.fields.location.venue && nEvent.fields.venue_location.addressLabel === '') {
            temp_error.location_address = true;
        }else {
            temp_error.location_address = false;
        }

        if (nEvent.fields.date.oneTime && nEvent.fields.oneTime.startDate === '') {
            temp_error.onceStartDate = true;
        }else {
            temp_error.onceStartDate = false;
        }

        if (nEvent.fields.date.oneTime && nEvent.fields.oneTime.endDate === '') {
            temp_error.onceEndDate = true;
        }else {
            temp_error.onceEndDate = false;
        }

        if (nEvent.fields.date.recurring && nEvent.fields.recurring.withEndDate && nEvent.fields.recurring.startDate === '') {
            temp_error.recurStartDate = true;
        }else {
            temp_error.recurStartDate = false;
        }

        if (nEvent.fields.date.recurring && nEvent.fields.recurring.withEndDate && nEvent.fields.recurring.endDate === '') {
            temp_error.recurEndDate = true;
        }else {
            temp_error.recurEndDate = false;
        }

        if (nEvent.fields.date.recurring && nEvent.fields.recurring.withEndDate && (!nEvent.fields.recurring.occurence || nEvent.fields.recurring.occurence.label === '--Please select--')) {
            temp_error.occurLabel = true;
        }else {
            temp_error.occurLabel = false;
        }

        if (nEvent.fields.date.recurring && nEvent.fields.recurring.withEndOccurence && nEvent.fields.recurring.startDate === '') {
            temp_error.recurStartDate = true;
        }else {
            temp_error.recurStartDate = false;
        }


        if (nEvent.fields.date.recurring && nEvent.fields.recurring.withEndOccurence && nEvent.fields.recurring.endOccurence === '') {
            temp_error.endOccur = true;
        }else {
            temp_error.endOccur = false;
        }

        if (nEvent.fields.date.recurring && nEvent.fields.recurring.withEndOccurence && (!nEvent.fields.recurring.occurence || nEvent.fields.recurring.occurence.value === '')) {
            temp_error.occurLabel = true;
        }else {
            temp_error.occurLabel = false;
        }

        setEventError({...temp_error});
        
        if (Object.entries(eventError).filter((item) => item[1]).length > 0){
            alert('Error Found');
            return false;
        }else {
            return true;
        }
    }
    
    const callValidateTicket = (nTicket:generalObj) => {
        let temp_filed:boolean = false;
        if (!nTicket.fields.eventHasTicket) {
            //setFilledTicket(true);
            temp_filed = true;
        }else {
            if (nTicket.fields.ticketCategory) {
                let results:Array<boolean> = [];
                nTicket.fields.categories.forEach((element:generalObj) => {
                    if (!element.isDeleted) {
                        if (element.price === "" || element.ticketNum === "") {
                            results.push(false)
                        }else {
                            results.push(true);
                        }
                    }
                });
                if (results.includes(false)) {
                    // setFilledTicket(false);
                    temp_filed = false;
                }else {
                    //setFilledTicket(true);
                    temp_filed = true;
                }
            }else {
                if (nTicket.fields.noCategory.price === "" || nTicket.fields.noCategory.ticketNum === "") {
                    //setFilledTicket(false);
                    temp_filed = false;
                }else {
                    //setFilledTicket(true);
                    temp_filed = true;
                }
            }
        }

        return temp_filed;
    }

    const switchTab = (currentId:string | number, nextTab:number, isRev:boolean = false) => {
        if (!scrollRef || !scrollRef.current) return;
        setEventField((prevEventField) => {
            if (!prevEventField) return null;
            return (
                prevEventField.filter((item, index) => {
                    if (item.id === currentId) {
                        item.isFilled = eventEditting && isRev? true : isRev ? false : true;
                        item.active = false
                    }

                    if (index === nextTab) {
                        item.active = true
                        item.isFilled = true
                    }
                    return item
                })
            )
        })

        // const next_tab = scrollRef.current.querySelector(`#event_tab_${nextTab}`);

        // next_tab?.scrollIntoView({behavior:'smooth'});
    }

    const promotionAssetsUpload = async (item:generalObj, nextTab:number) => {
        if (!item.fields.coverImage && !eventEditting) return;
        let imageErr = false;
        let videoErr = false;
        setModalLoading(true);
        if (imageErr && videoErr) {
            alert('unable to upload image and video');
        }else if (imageErr) {
            alert('unable to upload image');
        }else if (videoErr) {
            alert('unable to upload video');
        }else {
            setModalLoading(false);
            switchTab(item.id, nextTab);
        }
        setModalLoading(false);
    }

    const updateEventFields = (sectionId:string | number, fieldName:string, fieldSol:any) => {
        setEventField((prevEventField) => {
            if (!prevEventField) return null;
            return (
                prevEventField.filter((item) => {
                    if (item.id === sectionId) {
                        item.fields[fieldName] = fieldSol 
                    }
                    return item
                })
            )
        });
    }

    return (
        <>
            <NavBar updateOpenSearchModal={(val) => setOpenSearch(val)} />
            <div className={`appBody create__event loading`} style={{position:'relative'}} ref={parentRef}>
                
                <div style={{height:'100%'}}>
                    <div className="create__event__container" style={{padding:"0px 20px", height:"calc(100% - 80px)", paddingTop:"20px"}}>
                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%'}}>
                            <div style={{flexDirection:'row', alignItems:'center'}}>
                                <span className="sectionTitle">Create Event</span>
                            </div>
                            <button className="closeIcon" onClick={() => navigate(navigateRoute())}>
                                <IoCloseOutline size={"26px"} color="#FFF" />
                            </button>
                        </div>

                        <div style={{marginTop:"40px"}}>
                            <div className="tabbedPanel">
                                {eventField && eventField.map((item:generalObj, index:number) => (
                                    <div key={item.id}>
                                        <div className={`tabbedBorder${index === 0 ? " tabbedEdgeStart" : ""}${index === eventField.length - 1 ? " tabbedEdgeEnd" : ""}${item.isFilled ? " activeBorder" : ""}`}></div>
                                        <div className={`tabbedText bodyText${item.isFilled ? " activeText" : ""}`}>{item.title}</div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="tabbedPanelContent" ref={scrollRef}>
                            {eventField && eventField.map((item:generalObj, index:number) => (
                                item.active && (
                                    <div key={item.id} className="tabbedPanelContent__item" id={`event_tab_${index}`}>
                                        {item.id === "1" && <NewEvent item={item} editting={eventEditting} isError={eventError} onChange={(name, sol)=>updateEventFields(item.id, name, sol)} scrollRef={scrollRef} />}
                                        {item.id === "3" && <NewEventTicket item={item} editting={eventEditting} onChange={(name, sol)=>updateEventFields(item.id, name, sol)} />}
                                        {item.id === "2" && <NewEventPromotion itemuri={item} onChange={(name, sol)=>updateEventFields(item.id, name, sol)} parentObj={parentRef} />}
                                    </div>
                                )
                            ))}
                        </div>
                    </div>

                    {eventField && eventField.map((item, index) => (
                        <div key={item.id} className="create_event__action" style={{display:'flex', paddingTop:0, marginBottom:"10px", marginInline:"20px"}}>
                            {item.id === "1" && item.active && <ButtonFrame title="Next" contStyle={{backgroundColor:'#DB012E', border:"none",marginTop: 0, opacity:"1"}} onPress={() => {
                                if (callValidateEvent(item)) {
                                    switchTab(item.id, index+1);
                                }
                            }} />}
                            {item.id === "3" && item.active && (
                                <div style={{display:'flex', marginBottom:"10px", paddingTop:0, gap:"8px", width:"100%"}}>
                                    <ButtonFrame title="Back" contStyle={{backgroundColor:'rgba(255, 255, 255, 0.16)', marginTop: 0, width:"100%", border:"none"}} onPress={() => switchTab(item.id, index-1, true)} />
                                    <ButtonFrame title="Next" contStyle={{backgroundColor:'#DB012E', border:"none",marginTop: 0, opacity:"1"}} onPress={() => {
                                        if (callValidateTicket(item)) {
                                            setReviewObj([
                                                {
                                                    id:'1',
                                                    fields: eventField[0].fields
                                                },
                                                {
                                                    id:'2',
                                                    fields: eventField[1].fields
                                                },{
                                                    id:'3',
                                                    fields:eventField[2].fields
                                                }
                                            ])
                                            setOpenReview(true);
                                        }else {
                                            alert("All fields required");
                                        }
                                    }
                                    } />
                                </div>
                            )}
                            {item.id === "2" && item.active && (
                                <div style={{display:'flex', marginBottom:"10px", paddingTop:0, gap:"8px", width:"100%"}}>
                                    <ButtonFrame title="Back" contStyle={{backgroundColor:'rgba(255, 255, 255, 0.16)', marginTop: 0, width:"100%", border:"none"}} onPress={() => switchTab(item.id, index-1, true)} />
                                    <ButtonFrame title="Next" contStyle={{backgroundColor:'#DB012E', border:"none",marginTop: 0, opacity:item.fields.coverImage || item.fields.coverImageUrl? "1" : "0.5"}} onPress={() => promotionAssetsUpload(item, index+1)} />
                                </div>
                            )}
                        </div>
                    ))}

                    <Footer />
                </div>

            </div>
            <ModalLoader visible={modalLoading} />
            <ReviewEvent visible={openReview} eventObj={reviewObj} parentRef={parentRef} isEditting={eventEditting} modalClose={() => setOpenReview(false)} onChange={(name, sol)=>updateEventFields("3", name, sol)} />
            
            <SearchModal visible={openSearch} modalClose={() => setOpenSearch(false)} />
        </>
    )
}

export default CreateEvent;