import { useEffect, useRef, useState } from "react";
import { generalObj } from "../../constant/objects_types";
import EventItem from "./EventItem";
import LandingViewAllModal from "./LandingViewAllModal";
import { FaArrowLeftLong, FaArrowRightLong } from "react-icons/fa6";

const LandingEventCarousel = ({ item, parentWidth, title, onSavedEvent, showRecommendPop }:{
    item: generalObj[];
    parentWidth?: string;
    title: string;
    onSavedEvent: () => void;
    showRecommendPop: () => void;
}) => {
    const [events, setEvents] = useState<generalObj[] | null>();
    const [openViewAll, setOpenViewAll] = useState<boolean>(false);
    const carouselRef = useRef<HTMLDivElement>(null);
    
    useEffect(() => {
        if (!item) return;
        setEvents([...item]);
    }, [item]);

    const updateEventData = (action:string, id:number) => {
        console.log(action, id);
        // switch (action) {
        //     case 'save':
        //         setEvents((prevEvent) => (
        //             prevEvent.filter((item) => {
        //                 if (item.id === id) {
        //                     item.saved = !item.saved;
        //                 }
        //                 return item
        //             })
        //         ));
        //         onSavedEvent();
        //         break;
        //     case 'recommend':
        //         setEvents((prevEvent) => (
        //             prevEvent.filter((item) => {
        //                 if (item.id === id) {
        //                     item.recommended = item.recommended === undefined? false : !item.recommended;
        //                 }
        //                 return item
        //             })
        //         ));
        //         break;
        //     case 'follow':
        //         setEvents((prevEvent) => (
        //             prevEvent.filter((item) => {
        //                 if (item.id === id) {
        //                     item.following = !item.following;
        //                 }
        //                 return item
        //             })
        //         ));
        //         break;
        //     default:
        //         console.log(action);
        // }
    }

    const scrollLeft = () => {
        if (carouselRef.current) {
            let new_scroll_position = carouselRef.current.scrollLeft - 334; 
            carouselRef.current.scroll({top:0, left: new_scroll_position, behavior: "smooth"});
        }
    };

    const scrollRight = () => {
        if (carouselRef.current) {
            let new_scroll_position = carouselRef.current.scrollLeft + 334; 
            carouselRef.current.scroll({top:0, left: new_scroll_position, behavior: "smooth"}); 
        }
    };

    return (
        <>
            <div>
                <div className="sectionHeader sectionDisplay">
                    <p className="sectionTitle">{ title }</p>
                    <button className="sectionTopLink" onClick={() => setOpenViewAll(true)}>See All</button>
                </div>
                <div ref={carouselRef} className="flatList horizontal">
                    {events && events.map((itemObj:generalObj,  index:number) => (
                        <EventItem key={index} item={itemObj} listCont={{marginLeft:index === 0? "20px": "10px"}} imageCont={{height:`calc((${parentWidth} - 40px) / (4/3))`, width:`calc(${parentWidth} - 80px)`}} actionPressed={(action, id) => updateEventData(action, id)} updateEventItem={(id) => updateEventData("recommend", id)} openRecommendPop={()=>showRecommendPop()}  />
                    ))}
                </div>

                <div className="event_carousel_nav">
                    <button onClick={scrollLeft}>
                        <FaArrowLeftLong size={12} color="white" />
                    </button>
                    <button onClick={scrollRight}>
                        <FaArrowRightLong  size={12} color="white" />
                    </button>
                </div>
            </div>
            {openViewAll && events && (<LandingViewAllModal visible={openViewAll} link_name={title} item={events} modalClose={() => setOpenViewAll(false)} />)}
        </>
    )
};

export default LandingEventCarousel;
