import { useState, useEffect } from 'react';
import { UrlType, generalObj } from '../../constant/objects_types';
import BackArrow from '../BackArrow';
import { countryShortName, monthsCode } from '../../constant/shortcodes';
import ListProfile from './ListProfile';
import { IoChevronForwardOutline } from 'react-icons/io5';
import DateTimePickerModal from '../modal/DateTimePickerModal';
import PickerModal from '../modal/PickerModal';
import DeleteAccountModal from '../modal/DeleteAccountModal';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ProfileEdit from './ProfileEdit';
import { useSelector } from 'react-redux';
import { RootState, actionCreator } from '../../redux';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const PersonalInfoList = ({ visible, profileInfoItem, modalClose }:{ 
    visible:boolean, 
    profileInfoItem: generalObj, 
    modalClose: () => void
}) => {
    const profileInfo:generalObj = profileInfoItem;
    const profileDobDate: Date = new Date(profileInfo.dob);
    const profileDOB: string = `${profileDobDate.getDate()} ${monthsCode[profileDobDate.getMonth() + 1]} ${profileDobDate.getFullYear()}`;
    const [profileData, setProfileData] = useState<generalObj>({
        email:{
            key:'email',
            title:'Email',
            value:profileInfo.email || "",
            valueNoSpace: true,
            specialType: null,
            descriptiveText:"Enter your email",
            props: {
                type:'email'

            }
        },
        phone:{
            key:'phone',
            title:'Phone Number',
            value:profileInfo.phone || "",
            valueNoSpace: true,
            specialType: null,
            descriptiveText:"Input a new phone number.",
            props: {
                maxLength: 16,
                type:'number'
            }
        },
        dob:{
            key:'dob',
            title:'Birthday',
            valueNoSpace: false,
            specialType: 'picker',
            value:profileDOB,
            descriptiveText:"Select your birthday",
            props: {}
        },
        password:{
            key:'password',
            title:'Password',
            valueNoSpace: false,
            specialType: null,
            value:"",
            descriptiveText:"Enter new password",
            secureEntry: true,
            props: {
            }
        },
        gender:{
            key:'gender',
            title:'Gender',
            valueNoSpace: false,
            specialType: 'picker',
            value:profileInfo.gender || "",
            descriptiveText:"Select your gender",
            props: {}
        },
        country:{
            key:'country',
            title:'Country',
            valueNoSpace: false,
            specialType: 'picker',
            value:profileInfo.country,
            descriptiveText:"Select your country",
            props: {}
        }
    });

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editData, setEditData] = useState<generalObj | null>(null);
    const birthdayMax:Date = new Date();
    birthdayMax.setFullYear(birthdayMax.getFullYear() - 18);
    const birthdayMin:Date = new Date();
    birthdayMin.setFullYear(birthdayMin.getFullYear() - 100);
    const [openBirthDay, setOpenBirthDay] = useState<boolean>(false);
    const [birthDayDate, setBirthDayDate] = useState<Date | null>(null);

    const genderItem:generalObj[] = [
        {
            id:'00',
            label:'--Please select--',
            value:''
        },
        {
            id:'1',
            label:'Female',
            value:'Female'
        },
        {
            id:'2',
            label:'Male',
            value:'Male'
        },
        {
            id:'3',
            label:'Rather not say',
            value:'Rather not say'
        }
    ];
    const [openGenderPicker, setOpenGenderPicker] = useState<boolean>(false);
    const [countryItem, setCountryItem] = useState<generalObj[] | null>(null);
    const [openCountryPicker, setOpenCountryPicker] = useState<boolean>(false);
    const [openDeleteAcc, setOpenDeleteAcc] = useState<boolean>(false);
    
    const account = useSelector((state:RootState) => state.account);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { updateProfile } = bindActionCreators(actionCreator, dispatch);
    const { setModalLoading }:{ setModalLoading:Function } = useOutletContext();


    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!profileData.dob.value?.length) return;
        setBirthDayDate(new Date(profileData.dob.value));
    }, [profileData.dob.value]);

    useEffect(() => {
        let i = 1;
        let countryData:generalObj[] = [{
            id:'00',
            label:'--Please select--',
            value:''
        }];
        for (let key in countryShortName) {
            countryData.push(
                {
                    id: `${i}`,
                    label: countryShortName[key]['name'],
                    value: countryShortName[key]['name']
                }
            );
            i++;
        }
        setCountryItem(countryData);
    }, []);

    const setDataForEdit = (key:string) => {
        setEditData(profileData[key]);
        setOpenEdit(true);
    }

    const clearEditData = () => {
        setOpenEdit(false);
        setTimeout(() => {
            setEditData(null);
        }, 1000);
    }


    const updateProfileData = (pkey:string, value:any, valuekey:string="value") => {
        const Kdata = profileData[pkey]; 
        const updated_keyData = {...Kdata, [valuekey]: value};
        setProfileData({...profileData, [pkey]:updated_keyData});
        
        if (editData && editData.key === pkey) {
            setEditData(updated_keyData);
        }
    }

    const formatDate = (selectedDate:Date | null) => {
        if (!selectedDate) return;

        const currentDate = new Date(selectedDate);
        let fDate = `${currentDate.getDate()} ${monthsCode[currentDate.getMonth() + 1]} ${currentDate.getFullYear()}`;
        updateProfileData('dob', fDate);
        
    }

    const saveInput = async (key:string, value:any) => {
        if (!account) return;
        setModalLoading(true);
        await updateProfile(account.token, key, value, false);
        setModalLoading(false);
    }

    return (
        <>
            {firstShow && (
                <>
                    <div className={`personal__info modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                        <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Manage Account</div>
                                    </div>
                                </div>
                                
                                <div style={{width:'100%', marginTop:"40px", height:"100%"}}>
                                    <div style={{height:"calc(100% - 270px)", paddingBottom:'90px', overflowX:'hidden'}}>
                                        <ListProfile label={'Email'} value={profileData.email.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} onPress={()=>setDataForEdit(profileData.email.key)} />
                                        <ListProfile label={'Phone'} value={profileData.phone.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:32}} onPress={()=>setDataForEdit(profileData.phone.key)} />
                                        <ListProfile label={'Birthday'} value={profileData.dob.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:32}} inputStyle={{height:'auto', maxLength:"100px", paddingBottom:"16px"}} onPress={()=>setOpenBirthDay(true)} />
                                        <ListProfile label={'Password'} value={profileData.password.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:32}} inputStyle={{height:'auto', maxLength:"100px", paddingBottom:"16px"}} onPress={()=>setDataForEdit(profileData.password.key)} />
                                        <ListProfile label={'Gender'} value={profileData.gender.value.length ? profileData.gender.value : "--Please select--"} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:32}} inputStyle={{height:'auto', maxLength:"100px", paddingBottom:"16px"}} onPress={()=>setOpenGenderPicker(true)} />
                                        <ListProfile label={'Country'} value={profileData.country.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:32}} inputStyle={{height:'auto', maxLength:"100px", paddingBottom:"16px"}} onPress={()=>setOpenCountryPicker(true)} />

                                        {/* Delete account */}
                                        <button style={{alignSelf:'flex-start', marginTop:"32px", marginBottom:"10px"}} onClick={()=>setOpenDeleteAcc(true)}>
                                            <span className='sectionTopLink'>Delete Account</span>
                                        </button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}

            {editData && (<ProfileEdit 
                visible={openEdit} 
                item={editData} 
                onTextChange={(val)=>{
                    updateProfileData(editData.key, val);
                    clearEditData();
                }} 
                modalClose={clearEditData} 
            />)}

            {birthDayDate && (
                <DateTimePickerModal visible={openBirthDay} date={birthDayDate} mode={'date'} minimumDate={birthdayMin} maximumDate={birthdayMax} modalClose={()=>{
                    setOpenBirthDay(false);
                    if (profileData.dob.value === profileDOB) {
                        return;
                    }
                    saveInput('dob', `${birthDayDate.getFullYear()}-${birthDayDate.getMonth() + 1}-${birthDayDate.getDate()}`);
                }} onChange={(selectedDate) => formatDate(selectedDate)} />
            )}
            <PickerModal visible={openGenderPicker} pickerItem={genderItem} selectedValue={profileData.gender.value} onChange={(val) => {
                if (val.label === '--Please select--') return;
                updateProfileData('gender', val.value)
            }} modalClose={()=>{
                setOpenGenderPicker(false);
                if (profileData.gender.value === profileInfo.gender) {
                    return;
                }
                saveInput('gender', profileData.gender.value);
            }} />

            {countryItem && (
                <PickerModal visible={openCountryPicker} pickerItem={countryItem} selectedValue={profileData.country.value} onChange={(val) => {
                    if (val.label === '--Please select--') return;
                    updateProfileData('country', val.label)
                }} modalClose={()=>{
                    setOpenCountryPicker(false);    
                    if (profileData.country.value === profileInfo.country) {
                        return;
                    }
                    saveInput('country', profileData.country.value);
                }} />
            )}

            <DeleteAccountModal visible={openDeleteAcc} endFlow={()=>navigate(UrlType.LOGIN)} modalClose={()=>setOpenDeleteAcc(false)} />
        </>
    )
}

export default PersonalInfoList;