import { UrlType, generalObj } from "../../constant/objects_types"
import { RootState } from "../../redux";
import { TicketSmallSvg, PriceTagSmallSvg, TimeIconSvg, DateIconSvg, LocationSvg } from "../../styles/IconStyle";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getDateStr, getTimeStr, getUTCStr, processAddressnMap, setUpPrevUrl } from "../../action/generalFunc";
import ButtonFrame from "../ButtonFrame";
import { useNavigate, useOutletContext } from "react-router-dom";
import ProfileGenerator from "../ProfileGenerator";
import { SYMBOL } from "../../constant/constant";
import NavBar from "../NavBar";

const Hero = ({ item, showSearch=false, updateSearchObject }:{
    item:generalObj | null;
    showSearch?:boolean;
    updateSearchObject?: (val:boolean) => void;
}) => {

    // const [eventRating, setEventRating] = useState<boolean>(false);
    // const [quickComment, setQuickComment] = useState<boolean>(false);
    // const [eventCode, setEventCode] = useState<boolean>(false);
    const [eventMinTicket, setEventMinTicket] = useState<number>(0);
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();
    const { setTicketBuyAccount, setTicketId }:{ setTicketBuyAccount:Function, setTicketId:Function } = useOutletContext();

    useEffect(() => {
        if (!item || item.disabled) return;
        
        let min_ticket:number = 9999999999999;
        item.tickets.forEach((element:generalObj) => {
            if (min_ticket && (parseFloat(element.price) < min_ticket)) {
                min_ticket = parseFloat(element.price);
            }
        });
        setEventMinTicket(min_ticket);
    }, [item]);

    const assignTicketToPurchase = (ticket_id:number) => {
        setTicketBuyAccount(true); 
        setTicketId(ticket_id);
    }
    
    // const onRatingClose = () => {
    //     setQuickComment(true);
    //     setEventRating(false);
    // }

    return (
        <div className="hero" style={{backgroundImage:item ? `url(${item.cover_image}` : `url()`, height:"calc(100vh / (4/3))"}}>
            <div style={{position:'relative'}}>
                <div style={{position:'absolute', width:"100%", height:"100%"}} onClick={() => item && !item.disabled ? navigate(`${UrlType.EVENT_DETAIL}${item.id}`) : null}></div>
                {/* <div style={{position:'relative'}} className="navBar">
                    <div>
                        <div>
                            <img src={require('../../assets/logo.png')} alt={"logo"} className="logo" />
                        </div>
                    </div>
                    <ul>
                        {showSearch && <li>
                            <a href="#1">
                                <span>
                                    <SearchSvg />
                                </span>
                            </a>
                        </li>}
                        <li>
                            <a href="#1">
                                <span>
                                    <ScanCodeSvg />
                                </span>
                            </a>
                        </li>
                        <li style={{position:'relative'}}>
                            <a href="#1">
                                <span>
                                    <NotificationSvg />
                                    { isNotification && <span style={{width:"5px", height:"5px", borderRadius:"5px", backgroundColor:'#DB012E', position:'absolute', right:"6px", top:"4px"}}></span> }
                                </span>
                            </a>
                        </li>
                    </ul>
                </div> */}
                <NavBar barClass="show" updateOpenSearchModal={(val) => updateSearchObject && updateSearchObject(val)} />
                
                <div className="hero_content" style={{padding:"0px 20px", position:'relative'}}>
                    
                    {item ? (
                        !item.disabled && (<p className="heroTitle" onClick={() => navigate(`${UrlType.EVENT_DETAIL}${item.id}`)}>{item.name}</p>)
                    ): (
                        <div style={{backgroundColor:'rgba(255, 255, 255, 0.12)', width:'100%', height:26, borderRadius:10}}></div>
                    )}

                    {item ? (
                        !item.disabled && (
                            <a href={account && account.isAuthenticated ? (account.id === item.user_id ? `${UrlType.PROFILE_USER}me` : `${UrlType.PROFILE_USER_ID}${item.user_id}/`) : "#userprofile"} className="postOwner">
                                {item.user.profile_picture ? (
                                    <ProfileGenerator useImage={item.user.profile_picture} constStyle={{backgroundColor:'#c4c4c4'}} />
                                ): (
                                    <ProfileGenerator title={item.user.username[0].toUpperCase()} constStyle={{marginBottom:0}} textStyle={{fontSize:13, lineHeight:16}} />
                                )}
                                <p className="postOwnerText">{item.user.fullname}</p>
                                <div style={{justifyContent: "center",display: "flex",alignItems: "center"}}>
                                    <TicketSmallSvg />
                                </div>
                            </a>
                        )
                    ): (
                        <div className="postOwner" style={{alignItems:'center'}}>
                            <div className="profilePictureUser" style={{backgroundColor:'rgba(255, 255, 255, 0.12)'}}></div>
                            <div className="postOwnerText" style={{backgroundColor:'rgba(255, 255, 255, 0.12)', width:"100px", height:"16px", borderRadius:"10px"}}></div>
                        </div>
                    )}

                    {item ? (
                        !item.disabled && (
                            <div className="postOwner" onClick={() => navigate(`${UrlType.EVENT_DETAIL}${item.id}`)}>
                                <div style={{justifyContent: "center",display: "flex",alignItems: "center"}}>
                                    <PriceTagSmallSvg />
                                </div>
                                <p className="postOwnerText" >Price: {eventMinTicket ?`${SYMBOL}${parseFloat(`${eventMinTicket}`).toLocaleString()}` :'Free'}</p>
                            </div>
                        )
                    ): (
                        <div className="postOwner">
                            <div className="postOwnerText" style={{backgroundColor:'rgba(255, 255, 255, 0.12)', width:"200px", height:"16px", borderRadius:"10px"}}></div>
                        </div>
                    )}

                    {item ? (
                        !item.disabled && (
                            <div className="postOwner" style={{gap:"20px"}} onClick={() => navigate(`${UrlType.EVENT_DETAIL}${item.id}`)}>
                                <div style={{justifyContent: "center",display: "flex",alignItems: "center", gap:"5px"}}>
                                    <DateIconSvg /> 
                                    <p className="postOwnerText">Date: {getDateStr(getUTCStr(item.start_date))}</p>
                                </div>
                                <div style={{justifyContent: "center",display: "flex",alignItems: "center", gap:"5px"}}>
                                    <TimeIconSvg /> 
                                    <p className="postOwnerText">Time: {getTimeStr(getUTCStr(item.start_date))}</p>
                                </div>
                            </div>
                        )
                    ): (
                        <div className="postOwner">
                            <div style={{display:'flex'}}>
                                <div className="postOwnerText" style={{backgroundColor:'rgba(255, 255, 255, 0.12)', width:"120px", height:"16px", borderRadius:"10px"}}></div>     
                            </div>
                            <div style={{display:'flex', marginLeft:"20px"}}>
                                <div className="postOwnerText" style={{backgroundColor:'rgba(255, 255, 255, 0.12)', width:"120px", height:"16px", borderRadius:"10px"}}></div>     
                            </div>
                        </div>
                    )}

                    {item ? (
                        !item.disabled && (
                            <div className="postOwner" style={{alignItems:'center'}} onClick={() => navigate(`${UrlType.EVENT_DETAIL}${item.id}`)}>
                                <div style={{justifyContent: "center",display: "flex",alignItems: "center"}}>
                                    <LocationSvg />
                                </div>
                                <p className="postOwnerText">Location: {item.location_type === 'venue' ? `${processAddressnMap(item.location, "addressLabel")}` : "Online"}.</p>
                            </div>
                        )
                    ): (
                        <div className={"postOwner"}>
                            <div className="postOwnerText" style={{backgroundColor:'rgba(255, 255, 255, 0.12)', width:"200px", height:"16px", borderRadius:"10px"}}></div>
                        </div>
                    )}

                    {item && account ? (
                        <>
                            {!item.disabled && (
                                item.user_id === account.id ? (
                                    <ButtonFrame title="View your event" contStyle={{
                                        backgroundColor:'#DB012E', 
                                        border:"none",
                                        width: 'auto',
                                        padding:"6px 20px",
                                        position:"relative"
                                    }} onPress={() => navigate(`${UrlType.EVENT_DETAIL}${item.id}`)}  />
                                ): (
    
                                    <ButtonFrame title="Buy" contStyle={{
                                        backgroundColor:'#DB012E', 
                                        border:"none",
                                        width: 'auto',
                                        padding:"6px 20px",
                                        position:"relative"
                                    }} onPress={() => account && account.isAuthenticated ? navigate(setUpPrevUrl(window.location.pathname, `${UrlType.BUY_TICKET}${item.id}`)) : assignTicketToPurchase(item.id)}  />
                                )
                            )}
                        </>
                        
                    ): (
                        <div className={"postOwner"}>
                            <div className="postOwnerText buttonFrame" style={{
                                backgroundColor:'rgba(255, 255, 255, 0.12)', 
                                width: '80px'
                            }}></div>
                        </div>
                    )}
                </div>
                {/* <View style={[styles.heroContent, styles.heroContentBottom]}>
                    
                    
                </View> */}
            </div>
        </div>
    )
}

export default Hero;
