import { generalObj } from "../../constant/objects_types";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreator, RootState } from "../../redux";
import BackArrow from "../BackArrow";
import Switch from "../Switch";
import { useState, useEffect } from 'react';

const NotifyEdit = ({ visible, item, modalClose, onTextChange }:{
    visible:boolean,
    item:generalObj,
    modalClose: () => void,
    onTextChange: (key:string, id:string) => void
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const account = useSelector((state:RootState) => state.account);
    const dispatch = useDispatch();
    const { updateProfile } = bindActionCreators(actionCreator, dispatch);


    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const saveInput = async (key:string, id:string, slug:string, val:boolean) => {
        if (!account)return;
        onTextChange(key, id);
        let new_value = val === true ? 1 : 0;
        if (slug === "ticket_purchase") {
            await updateProfile(account.token, "ticket_refund", new_value, false);
        }
        await updateProfile(account.token, slug, new_value, false);
    }

    return (
        <>
           {firstShow && ( 
                <div className={`notify__edit modalParentContainer${visible ? " animate__animated animate__slideInRight" : " animate__animated animate__slideOutRight" }`} style={{backgroundColor:"#0D0404"}}>
                    <div className="modalContainer" style={{alignItems:'flex-start'}}>
                        <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                            <div style={{display:'flex', gap:"0px", alignItems:'center', width:'100%', marginTop:"20px"}}>
                                <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>{item.title}</div>
                            </div>
                            <div style={{marginTop:"32px"}}>
                                {item.options.map((notif:generalObj, index:number) => (
                                    <div key={notif.id} style={{paddingBottom:"16px", borderBottom:"1px solid rgba(255, 255, 255, 0.1)", marginTop: index === 0 ? "0px" : "24px"}}>
                                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                            <span className="locationRegion" style={{ lineHeight:"16px" }}>{notif.title}</span>
                                            <div style={{display:'flex', alignItems:'center', justifyContent:'center', padding:"0px", gap:'5px'}}>
                                                <span className="inputLabel" style={{marginRight:"0px"}}>{notif.active? 'On' : 'Off'}</span>
                                                <div style={{}}>
                                                    <Switch 
                                                        onValueChange={(val)=>saveInput(item.key, notif.id, notif.slug, val)}
                                                        value={notif.active}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <p className="locationRegion" style={{ lineHeight:"16px", fontSize:"13px", color:'#9E9B9B', marginTop:"12px" }}>{notif.description}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    
                </div>
           )}
        </>
    )
}

export default NotifyEdit;