import { useEffect, useState, useRef } from "react";
import { BackArrow, RegisterContent, ModalLoader } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState, ractionCreator, actionCreator } from "../../redux";
import { useNavigate } from "react-router-dom";
import { UrlType } from "../../constant/objects_types";
import { UnfilledRadioSvg, FilledRadioSvg } from "../../styles/IconStyle";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";
import { validatePassword } from "../../action/generalFunc";
import { countryShortName } from "../../constant/shortcodes";

const RegisterStepFour = () => {
    type msgRObj = {
        [key:string]:any
    }
    const resgisterState = useSelector((state:RootState) => state.registerInfo); 
    const account = useSelector((state:RootState) => state.account);   
    const [hidePassword, setHidePassword] = useState<boolean>(true);
    const [password, setPassword] = useState<string>('');
    const [passErr, setPassErr] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [passwordCheckList, setPasswordCheckList] = useState<msgRObj>([
        {
          id:'1',
          text: "Includes uppercase and lower letter",
          checked: false,
          identifier: 'isUpper',
          color: '#FFF'
        },{
          id:'2',
          text: "Includes at least one special character (!,*,#,%...)",
          checked: false,
          identifier: 'isSpecial',
          color: '#FFF'
        },{
          id:'3',
          text: "Must be 8 characters or longer",
          checked: false,
          identifier: 'isLength',
          color: '#FFF'
        },{
          id:'4',
          text: "Includes at least one number",
          checked: false,
          identifier: 'isNumber',
          color: '#FFF'
        }
    ])
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { updateInfo, clearInfo } = bindActionCreators(ractionCreator, dispatch);
    const { register, login } = bindActionCreators(actionCreator, dispatch);

    const passwordRef = useRef<HTMLInputElement | null>();

    useEffect(() => {
        if (!resgisterState || !resgisterState.email || !resgisterState.fullname || !resgisterState.username) {
            navigate(UrlType.REGISTER_STEP1);
            return;
        }
    }, [resgisterState, navigate]);

    const checkPassword = () => {
      if (password.length === 0) return;
        let result = validatePassword(password);
        setPassErr(!result);
        return result;
    }

    const setTextChange = (val:string) => {
        let result:msgRObj = validatePassword(val);
        setPassword(val); 
        setPasswordCheckList((prevPasswordCheckList) => {
          return (
            prevPasswordCheckList.filter((item:msgRObj) => {
              item.checked = result[item.identifier];
              item.color = '#FFF';
              return item
            })
          )
        })
        if (val.length > 0) {
          let valueList = Object.values(result);
          setPassErr(valueList.includes(false));
        }else {
          setPassErr(false);
        }
    }

    useEffect(() => {
        if (!account || !account.isAuthenticated || !account.isOnboarding) return;
        setModalLoading(false);
        clearInfo();
        navigate(UrlType.KYC);
    }, [account, clearInfo, navigate]);

    const onSubmit = async () => {
        if (!resgisterState.email) return;
        if (passErr || password.length === 0 || passwordCheckList.filter((item:msgRObj) => item.checked === false).length > 0) {
          setPasswordCheckList((prevPasswordCheckList) => {
            return (
              prevPasswordCheckList.filter((item:msgRObj) => {
                if (!item.checked) {
                  item.color = '#F00D35';
                }
                return item
              })
            )
          });
          return;
        };
        
        setModalLoading(true); 
        const sN:string | null = localStorage.getItem('userLoacation')
        const country =  countryShortName[sN === null ? 'NG' : sN].name;
        await updateInfo("password", password);
        
        let newUser = await register({...resgisterState, password:password, country: country});
        if (!newUser) {
          setModalLoading(false);
        }else {
          let logNewUser = await login(resgisterState.email, password);
          if (!logNewUser) {
            setModalLoading(false);
          }
        }
    }

    return (
        <>
            <div className='registerAuth step4 authContainer' style={{justifyContent:'flex-start'}}>
                <BackArrow onPress={() => {window.history.back()}} />
                <div className="register">
                    <RegisterContent 
                        header="Create a password" 
                        btnTitle="Submit" 
                        id={'username'}
                        description="Choose a strong password for your account."
                        inputProp={{value:password, type:hidePassword?'password':'text', onBlur:checkPassword}}
                        refInput={(input)=>passwordRef.current = input}
                        isIcon={hidePassword ? <IoEyeOffOutline size={"16px"} color="rgba(255, 255, 255, 0.6)" /> :<IoEyeOutline size={"16px"} color="rgba(255, 255, 255, 0.6)" /> }  
                        onPress={onSubmit}
                        btnStyle={{opacity:'1', cursor:"pointer"}}
                        onTextChange={(val)=>{setTextChange(val)}}
                        iconPress={() => setHidePassword(!hidePassword)}
                        message={passErr ? {
                            style: {
                                color: '#F00D35'
                            },
                            show: passErr,
                            text: "Invalid password"
                        }: {}}
                    />
                    <div style={{width:'100%', marginTop:57}}>
                        {passwordCheckList.map((item:msgRObj, index:number) => (
                            <div key={index} style={{display:'flex', justifyContent:'flex-start', alignItems:'center', marginTop:index === 0?"0px":"8px"}}>
                                <span>{item.checked ? <FilledRadioSvg /> : <UnfilledRadioSvg /> }</span>
                                <span className="inputLabel" style={{lineHeight:"19px", color:item.checked ? '#FFF' : item.color, marginLeft:"8px"}}>{item.text}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <ModalLoader visible={modalLoading} />
        </>
    )
}

export default RegisterStepFour;