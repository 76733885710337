import { Outlet } from "react-router-dom"
import { NavBar } from "../../components"
const UserRegister = () => {
    return (
        <>
            <div className='authNav'>
                <NavBar updateOpenSearchModal={() => null} />
            </div>
            <Outlet />
        </>
    )
}

export default UserRegister