import { IoEllipse } from "react-icons/io5";
import { generalObj } from "../../constant/objects_types";
import { useState, useRef } from 'react';
import EventImageCropper from "./EventImageCropper";
import { getBlobUri } from "../../action/generalFunc";
//import imageCompression from 'browser-image-compression';
import ModalLoader from "../modal/ModalLoader";
import ReactPlayer from "react-player";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from "react-toastify";

const ImageMsg = ({ message }:{
    message:JSX.Element
}) => (
    <div>
      <div>{message}</div>
    </div>
);

const NewEventPromotion = ({ itemuri, onChange, parentObj }:{ 
    itemuri: generalObj,
    onChange: (name:string, val:any) => void,
    parentObj: generalObj
}) => {
    const [image, setImage] = useState<string | null>(null);
    const [video, setVideo] = useState<string | null>(null);
    //const videoRef = useRef<HTMLVideoElement | null>(null);
    const [newImage, setNewImage] = useState<boolean>(true);
    const [imageCropping, setImageCropping] = useState<string | null>(null);
    const [openCropper, setOpenCropper] = useState<boolean>(false);
    //const [imageObj, setImageObj] = useState<Blob | null>(null);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const assetList:generalObj[] = [
        {
            id:'1',
            active:true
        },{
            id:'2',
            active:true
        }
    ];

    const assetListRef = useRef<HTMLDivElement | null>(null);
    const imageInputRef = useRef<HTMLInputElement | null>(null);
    const videoInputRef = useRef<HTMLInputElement | null>(null);
    const parentRef = useRef<HTMLInputElement | null>(null);
    const [touchStart, setTouchStart] = useState<number>(0);
    // const options:generalObj = {
    //     maxSizeMB: 1,
    // }


    const openImage = () => {
        if (!imageInputRef.current) return;

        console.log(imageInputRef.current);
        imageInputRef.current.click();
    }

    const openVideo = () => {
        if (!videoInputRef.current) return;
        videoInputRef.current.click();
    }

    const pickImage = async (e:generalObj) => {
        if (!e.target.files.length) return;
        if (e.target.files[0].size > 3000000) {
            toast(<ImageMsg message={<div>Cover image is greater than 3mb, click <a href='https://www.youtube.com/shorts/8ovvx07KTM4' rel="noreferrer" target="_blank" style={{color:'#DB012E'}}>here</a> to watch the video on how to reduce it.</div>} />);
            e.target.value = "";
            return;
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0]);
        reader.onload = () => {
            if (!reader || !reader.result) return;

            const blob = new Blob([reader.result]);
            const url = URL.createObjectURL(blob);
            setImageCropping(url);
            setOpenCropper(true);
        }
    };

    const pickVideo = async (e:generalObj) => {
        if (!e.target.files.length) return;
        if (e.target.files[0].size > 8000000) {
            alert("Video should not be greater than 8mb");
            e.target.value = "";
            return;
        }
        const reader = new FileReader();
        reader.readAsArrayBuffer(e.target.files[0]);
        reader.onload = () => {
            if (!reader || !reader.result) return;

            const blob = new Blob([reader.result], {type: "video/mp4"});

            const url = URL.createObjectURL(blob);
            setVideo(url);
            onChange("promotionVideo", blob);
            onChange("promotionVideoUrl", "");
        }
    };

    const onModalClose = () => {
        setOpenCropper(false);
        setTimeout(() => {
            setImageCropping(null);
            if (imageInputRef.current) {
                imageInputRef.current.value = "";
            }
        }, 1000);
    }

    const handleImageCropped = async (val:Blob) => {
        setModalLoading(true);
        const url = URL.createObjectURL(val);
        setImage(url);
        //setImageObj(val);
        // let toFile = new File([val], `${Math.ceil(Math.random() * 100000000)}.png`, {type:"image/png"});
        // try {
        //     const compressedFile = await imageCompression(toFile, options);
        //     onChange("coverImage", compressedFile);
        //     onChange("coverImageUrl", "");
        // } catch (error) {
        //     console.log(error);
        // }
        onChange("coverImage", val);
        onChange("coverImageUrl", "");
        setModalLoading(false);
        
        onModalClose();
    }

    const handleSwitch = (target:string, val:boolean) => {
        if (!assetListRef || !assetListRef.current) return;
        assetListRef.current.querySelector(target)?.scrollIntoView({behavior:'smooth'});
        setNewImage(val);
    }

    const handleScroll = (evt:generalObj, deltaX:number=0) => {
        if (window.innerWidth > 768) return;
        const parentElem = evt.target.parentElement;
        if (parentElem.id === "imgID" && (evt.deltaX > 0 || deltaX < 0)) {
            handleSwitch("#promotion__2", false)
        }else if (parentElem.id !== "imgID" && (evt.deltaX < 0 || deltaX > 0)) {
            handleSwitch("#promotion__1", true)
        }
    }

    const handleTouchStart = (e:generalObj) => {
        setTouchStart(e.touches[0].clientX);
    }


    const handleTouchMove = (e:generalObj) => {
        let deltaX = e.touches[0].clientX - touchStart;
        handleScroll(e, deltaX)
    }

    return (
        <div style={{height:"100%"}} ref={parentRef}>
            <div className="inputLabel" style={{lineHeight:"16px", marginTop:"32px", textAlign:'center'}}>Upload the cover image for your event and a promotion video to get people hyped up. This image would be used for the ticket as well.</div>
            <div style={{marginTop:"24px"}}>
                <div className="promotion__container" ref={assetListRef} onWheel={(e) => handleScroll(e)} onTouchStart={(e) => handleTouchStart(e)} onTouchMove={(e) => handleTouchMove(e)}>
                    {assetList.map((item, index) => (
                        <div key={index} className="promotion__item" id={`promotion__${item.id}`}>
                            {item.id === '1' && (
                                <>
                                    <div className="inputLabel" style={{marginBottom:"12px", textAlign:'center', color:'#FFF', fontWeight:"bold"}}>Cover Image</div>
                                    <button id="imgID" className="borderColor" style={{border:"1px dashed rgba(255, 255, 255, 0.6)", borderRadius:"12px", display:'flex', justifyContent:'center', alignItems:'center', width: !parentRef || !parentRef.current ? `100%`: `${parentRef.current.clientWidth}px`, height: !parentRef || !parentRef.current ? `75%`: `${parentRef.current.clientWidth * (3/4)}px`}} onClick={openImage}>
                                        {image || itemuri.fields.coverImage || itemuri.fields.coverImageUrl ? (
                                            <img src={image ? image: itemuri.fields.coverImage ? getBlobUri(itemuri.fields.coverImage) : itemuri.fields.coverImageUrl} style={{width:'100%', aspectRatio:"4 / 3", borderRadius:"12px"}} alt="cover"  />
                                        ): (
                                            <div style={{width:'100%', aspectRatio:"4 / 3", borderRadius:"12px", display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                <div className="sectionTopLink" style={{fontSize:"13px", lineHeight:"33px", marginTop:"30.5px"}}>Import Image</div>
                                                <div className="inputLabel" style={{lineHeight:"33px", marginTop:"8px", marginBottom:"30.5px"}}>Only support jpg, svg and png</div>
                                            </div>
                                        )}
                                    </button>
                                    <input type={"file"} accept={"image/*"} ref={imageInputRef} onChange={(e) => pickImage(e)} style={{display:"none"}}  />
                                </>
                            )}
                            {item.id === '2' && (
                                <>
                                    <div className="inputLabel" style={{marginBottom:"12px", textAlign:'center', color:'#FFF', fontWeight:"bold"}}>Promotional Video <span className="inputLabel" style={{lineHeight:"16px", textAlign:'center'}}>(Optional)</span></div>
                                    <button className="borderColor" id="videoID" style={{border:"1px dashed rgba(255, 255, 255, 0.6)", borderRadius:"12px", display:'flex', justifyContent:'center', alignItems:'center', width: !parentRef || !parentRef.current ? `100%`: `${parentRef.current.clientWidth}px`, height: !parentRef || !parentRef.current ? `75%`: `${parentRef.current.clientWidth * (3 / 4)}px`}} onClick={openVideo}>
                                        {video || itemuri.fields.promotionVideo ? (
                                            // <video 
                                            //     ref={videoRef}
                                            //     className="displayImage"
                                            //     style={{width:'100%',height:'100%', borderRadius:"12px", objectFit:'contain'}}
                                            //     src={video ? video: getBlobUri(itemuri.fields.promotionVideo)}
                                            //     loop={true}
                                            //     muted={true}
                                            //     autoPlay={true}
                                            // />
                                            <ReactPlayer url={video ? video: getBlobUri(itemuri.fields.promotionVideo)} style={{width:'100%',height:'100%', borderRadius:"12px", objectFit:"contain"}}  playing={true} loop={true} muted={true} playsinline={true} />
                                        ): (
                                            <>
                                                <div style={{width:'100%', aspectRatio:"4 / 3", borderRadius:"12px", display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                                                    <div className="sectionTopLink" style={{fontSize:"13px", lineHeight:"33px", marginTop:"30.5px"}}>Import Video</div>
                                                    <div className="inputLabel" style={{lineHeight:"33px", marginTop:"8px", marginBottom:"30.5px"}}>Only support mp4 and mov</div>
                                                </div>
                                            </>
                                        )}
                                    </button>
                                    <input type={"file"} accept={"video/*"} ref={videoInputRef} onChange={(e) => pickVideo(e)} style={{display:"none"}}  />
                                </>
                            )}
                        </div>
                    ))}
                </div>

                <div className="carousel__navigator" style={{display:'flex', marginTop:"40px", justifyContent:'center', alignItems:'center',gap:"7px"}}>
                    <button onClick={()=>handleSwitch("#promotion__1", true)}>
                        <IoEllipse size={"10px"} color={newImage?'#FFFFFF':'rgba(255, 255, 255, 0.12)'} />
                    </button>
                    <button onClick={()=>handleSwitch("#promotion__2", false)}>
                        <IoEllipse size={"10px"} color={!newImage?'#FFFFFF':'rgba(255, 255, 255, 0.12)'} />
                    </button>
                </div>
            </div>
            {imageCropping && parentObj && <EventImageCropper visible={openCropper} modalClose={() => onModalClose()} source={imageCropping} onCropChange={(valObj) => handleImageCropped(valObj)} parentRef={parentObj} />}
            <ModalLoader visible={modalLoading} />
            <ToastContainer autoClose={false} theme="dark" position="bottom-center" />
        </div>
    )
}

export default NewEventPromotion;