import BackArrow from "../BackArrow";
import { useEffect, useRef, useState } from "react";
import { generalObj } from "../../constant/objects_types";
import ReactCrop, { centerCrop, makeAspectCrop, type Crop} from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import ModalLoader from "../modal/ModalLoader";

const setCanvasImage = (image:HTMLImageElement, canvas:HTMLCanvasElement, crop:Crop) => {
    if (!image || !canvas || !crop) return;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    if (ctx) {
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );
    }
}

const EventImageCropper = ({ visible, modalClose, source, onCropChange, parentRef }:{
    visible:boolean,
    modalClose: () => void,
    source:string,
    onCropChange: (val:Blob) => void,
    parentRef: generalObj
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<Crop | null>(null);
    const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const imgRef = useRef<HTMLImageElement | null>(null);
    
    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const onImageLoad = (e:generalObj) => {
        const { naturalWidth: width, naturalHeight: height } = e.currentTarget
        
        const crop = centerCrop(
          makeAspectCrop(
            {
              unit: '%',
              width: 100,
            },
            4 / 3,
            width,
            height
          ),
          width,
          height
        )
      
        setCrop(crop);
        setCompletedCrop(crop);
    }

    useEffect(() => {
        if (!imgRef.current || !previewCanvasRef.current || !completedCrop) return;
        setCanvasImage(imgRef.current, previewCanvasRef.current, completedCrop)
    }, [completedCrop]);


    const processNewImage = async () => {
        if (!previewCanvasRef.current || !completedCrop) return false;
        setModalLoading(true);
        const canvas = previewCanvasRef.current;
        canvas.toBlob(async (blob:Blob | null) => {
            if (!blob){
                alert("Image object failed, change image size or select another image.");
                modalClose();
                setModalLoading(false);
                return;
            }
            onCropChange(blob);
            setModalLoading(false);
        }, "image/jpeg", 0.8);
    }
    
    return (
        <>
            {firstShow && (
                <div className={`event__container modalParentContainer${visible ? " animate__animated animate__slideInRight" : " animate__animated animate__slideOutRight" }`} style={{backgroundColor:"#0D0404", left:"0px"}}>
                    <div className="modalContainer event__cropper" style={{alignItems:'flex-start', flexDirection:'column'}}>
                        <div style={{width:'calc(100% - 40px)', display:"flex", justifyContent:'space-between', paddingTop:"20px", marginLeft:'auto', marginRight:'auto'}}>
                            <div style={{display:'flex', gap:"0px", alignItems:'center', width:'100%'}}>
                                <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Crop Picture</div>
                            </div>
                            <button onClick={processNewImage}>
                                <span className="sectionTitle" style={{fontSize:"15px", lineHeight:"16px", color:'#DB012E'}}>Done</span>
                            </button>
                        </div>

                        <div className="event__cropper_view" style={{height:"100%", width:'100%', padding:"40px 0px", position:'relative', display: "flex", flexDirection: "column",justifyContent: "space-between",paddingBottom: "90px"}}>
                            <div className="cropper_image__container" style={{width:`${parentRef.current ? `${parentRef.current.clientWidth}px` : "280px"}`, height:`${parentRef.current ? `${parentRef.current.clientWidth}px` : "280px"}`, display:'flex', justifyContent:'center', alignItems:'center', position:'relative', zIndex:"1"}}>
                                <div style={{width:"100%", height:"100%"}}>
                                    <ReactCrop crop={crop} locked={true} keepSelection={true} aspect={4 / 3} onChange={(e) => setCrop(e)} onComplete={(e) => setCompletedCrop(e)}>
                                        <img src={source} alt="profile" ref={imgRef} onLoad={onImageLoad} />
                                    </ReactCrop>
                                </div>
                            </div>
                            <div style={{position:'absolute', top:"50%", visibility:'hidden', transform:"translateY(-50%)"}}>
                                {completedCrop && (
                                    <canvas 
                                        ref={previewCanvasRef}
                                        onChange={(e) => console.log(e)}
                                        style={{
                                            width:`${parentRef.current?.clientWidth ?? "280"}px`,
                                            height:`${parentRef.current?.clientWidth / (4/3) ?? "280"}px`
                                        }}
                                    />
                                )}
                                
                            </div>
                        </div>
                    </div>
                    
                </div>
            )}
            <ModalLoader visible={modalLoading} />
        </>
    )
}

export default EventImageCropper;