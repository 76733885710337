import { useEffect, useState } from "react";
import { BackArrow, ModalLoader, KycListCont, ButtonFrame } from "../../components";
import { completeKyc } from "../../action/generalFunc";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState, actionCreator } from "../../redux";
import { useNavigate } from "react-router-dom";
import { UrlType, generalObj } from "../../constant/objects_types";

const Kyc = () => {
    const [isBack, setIsBack] = useState<boolean>(false);
    const [listTab, setListTab] = useState<generalObj[]>([
        {
            id: 1,
            title:'Music Events',
            active: true,
            isFilled: false,
            isAnim: false,
            component: [
                {
                    id: 1,
                    image: require('../../assets/img/genre/afropop.png'),
                    title: 'Afro Pop',
                    selected: false
                },
                {
                    id: 2,
                    image: require('../../assets/img/genre/pop.png'),
                    title: 'Pop',
                    selected: false
                },
                {
                    id: 3,
                    image: require('../../assets/img/genre/rap.png'),
                    title: 'Rap',
                    selected: false
                },
                {
                    id: 4,
                    image: require('../../assets/img/genre/edm.png'),
                    title: 'EDM',
                    selected: false
                },
                {
                    id: 5,
                    image: require('../../assets/img/genre/rock.png'),
                    title: 'Rock',
                    selected: false
                },
                {
                    id: 6,
                    image: require('../../assets/img/genre/gospel.png'),
                    title: 'Gospel',
                    selected: false
                },
                {
                    id: 7,
                    image: require('../../assets/img/genre/afrorap.png'),
                    title: 'Afro Trap',
                    selected: false
                },
                {
                    id: 8,
                    image: require('../../assets/img/genre/kpop.png'),
                    title: 'Kpop',
                    selected: false
                },
                {
                    id: 9,
                    image: require('../../assets/img/genre/reggae.png'),
                    title: 'Reggae',
                    selected: false
                },
                {
                     id: 10,
                    image: require('../../assets/img/genre/rnb.png'),
                    title: 'R&B',
                    selected: false
                },
                {
                     id: 11,
                    image: require('../../assets/img/genre/jazz.png'),
                    title: 'Jazz',
                    selected: false
                },
                {
                     id: 12,
                    image: require('../../assets/img/genre/trap.png'),
                    title: 'Trap',
                    selected: false
                }
            ]
        },
        {
            id: 2,
            title:'Other Events',
            active: false,
            isFilled: false,
            isAnim: false,
            component: [
                {
                    id: 1,
                    image: require('../../assets/img/otherse/comedy.png'),
                    title: 'Comedy Shows',
                    selected: false
                },
                {
                     id: 2,
                    image: require('../../assets/img/otherse/house.png'),
                    title: 'House Parties',
                    selected: false
                },
                {
                    id: 3,
                    image: require('../../assets/img/otherse/sport.png'),
                    title: 'Sports',
                    selected: false
                },
                {
                    id: 4,
                    image: require('../../assets/img/otherse/conference.png'),
                    title: 'Conferences',
                    selected: false
                },
                {
                    id: 5,
                    image: require('../../assets/img/otherse/religious.png'),
                    title: 'Religious Events',
                    selected: false
                },
                {
                    id: 6,
                    image: require('../../assets/img/otherse/food.png'),
                    title: 'Food Festivals',
                    selected: false
                },
                {
                    id: 7,
                    image: require('../../assets/img/otherse/dance.png'),
                    title: 'Dance Events',
                    selected: false
                },
                {
                    id: 8,
                    image: require('../../assets/img/otherse/games.png'),
                    title: 'Games Night',
                    selected: false
                },
                {
                    id: 9,
                    image: require('../../assets/img/otherse/club.png'),
                    title: 'Club Events',
                    selected: false
                }
            ]
        }
    ]);
    const [modalLoading, setModalLoading] = useState<boolean>(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const account = useSelector((state:RootState) => state.account);
    const { onboarding } = bindActionCreators(actionCreator, dispatch);

    useEffect(() => {
        if (account && account.isAuthenticated && account.isOnboarding) return;
        setModalLoading(false);
        navigate(UrlType.HOME);
    }, [account, navigate]);

    const switchTab = (id:number) => {
        const current_active = listTab.filter(item => item.active === true);

        if (current_active.length && current_active[0].id === id) return;
        
        setListTab((prevListTab) => {
            return (
                prevListTab.filter(tabname => {
                    if (current_active.length && current_active[0].id === tabname.id) tabname.active = false;
                    if (tabname.id === id && !tabname.active) {
                        tabname.active = true;
                    }
                    return tabname
                })
            )
        })
    }

    const updateIsAnim = (id:number) => {
        setListTab((prevListTab) => {
            return (
                prevListTab.filter(tabname => {
                    if (tabname.id === id) {
                        tabname.isAnim = true;
                    }
                    return tabname
                })
            )
        })
    }

    const submitTab = async () => {
        if (!account) return;
        const current_active = listTab.filter(item => item.active === true);
        if (!current_active.length) return;

        const get_selected_item = current_active[0].component.filter((item:generalObj)=> item.selected === true);

        if (!get_selected_item.length) return;

        if (current_active[0].id === 1) {
            switchTab(2);
            setIsBack(true);
            updateIsAnim(1);
            setListTab((prevListTab) => {
                return (
                    prevListTab.filter(tabname => {
                        if (tabname.id === 1) tabname.isFilled = true;
                        return tabname
                    })
                )
            })
        }else {
            setModalLoading(true);

            const selected_list:object[] = [];
            listTab.forEach(tab => {
                tab.component.forEach((item:generalObj) => {
                    if (item.selected === true) {
                        selected_list.push(item.title);
                    }
                })
            })
            

            const result = await completeKyc(selected_list, account.token);
            if (result) {
                await onboarding();
            }
            else {
                setModalLoading(false);
            }
        }
    }

    const switchBack = () => {
        switchTab(1);
        setIsBack(false);
        updateIsAnim(2);
    }

    const skipBack = async () => {
        await onboarding();
    }

    return (
        <>
            <div className='kycAuth authContainer' style={{justifyContent:'flex-start'}}>
                { isBack && <BackArrow onPress={switchBack} /> }

                <div style={{position:'absolute', right:15, top:12}}>
                    <button style={{color:'#DB012E', fontSize:"14px", fontWeight:"600",lineHeight: "19px", cursor:"pointer"}}  onClick={skipBack}>
                        <span>Skip</span>
                    </button>
                </div>
                <div className="register">
                    <div className="kyc__header">
                    
                        <h1 className="header">Tell us what you’re into</h1>
                        <p className="desc">We would like to know what kind of events you’re interested in, so we can make better recommendations for you. </p>
                    
                        <div className="tabbedPanelCont">
                            {listTab.map((item, index) => (
                                <div key={item.id} className="" style={{width:`${((12/listTab.length)/12)*100}%`}}>
                                    <div className={`tabbedBorder${item.active || item.isFilled ? " activeBorder":""}`}></div>
                                    <div className={`tabbedText${item.active || item.isFilled ? " activeText": ""}`}>{item.title}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {listTab.filter(activeItem => activeItem.active).map(item => (
                        // item.component
                        <KycListCont key={item.id} data={item.component} isAnim={item.isAnim} />
                    ))}

                    <ButtonFrame title="Next" contClass={{position: "absolute", width: "calc(100% - 40px)", bottom: "20px"}} contStyle={{backgroundColor:'#DB012E', border:"none"}} onPress={submitTab} />
                </div>
                
            </div>

            <ModalLoader visible={modalLoading} />
        </>
    )
}

export default Kyc;