import '../styles/style.css';
import { BottomTab, Hero, HomeDivider, LandingEventCarousel, LocationSetter, LocationModal, SearchModal, Footer } from '../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState, actionCreator } from '../redux';
import { UrlType, generalObj } from '../constant/objects_types';
import { useState, useEffect, useRef } from 'react';
import { fetchLanding, updateEventSaved } from '../action/generalFunc';
import { SearchInputSvg, NextIconSmSvg } from '../styles/IconStyle';
import { useUserLocation } from '../ticki_hooks';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

const Landing = () => {
    const [locationData, locationStateData] = useUserLocation();
    const [locationModal, setLocationModal] = useState<boolean>(false);
    const [newLocation, setNewLocation] = useState<string | null>(null);


    const [onGoingEvent, setOnGoingEvent] = useState<generalObj[] | null>(null);
    const [savedEvent, setSavedEvent] = useState<generalObj[] | null>(null);
    // const [trendingEvent, setTrendingEvent] = useState<generalObj[] | null>(null);
    const [upComingEvent, setUpComingEvent] = useState<generalObj[] | null>(null);
    const [promotedEvent, setPromotedEvent] = useState<generalObj | null>(null);
    const [itemBlock, setItemBlock] = useState<boolean>(false);
    const [recommendPop, setRecommendPop] = useState<boolean>(false);
    const [openSearch, setOpenSearch] = useState<boolean>(false);


    //const [refreshed, setRefreshed] = useState<boolean>(false);
    const landingRef = useRef<HTMLDivElement | null>(null);

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const account = useSelector((state:RootState) => state.account);
    const { loadOnboarding } = bindActionCreators(actionCreator, dispatch);

    console.log(recommendPop);

    useEffect(() => {
       // if (document.activeElement !== landingRef.current && !refreshed) return;
        const loadEvents = async () => {
            const result:generalObj = await fetchLanding(account?.token);
            let promoteHero:generalObj[] = [];
            if (!result.status) {
              alert(result.message);
            }else if (result.data.error) {
              alert(result.data.message);
            }else {
              setOnGoingEvent(result.data.data.ongoing_events.length > 0 ? result.data.data.ongoing_events : null);
              if (result.data.data.ongoing_events.length) {
                let random_promotedEvent_ong = Math.floor(Math.random() * result.data.data.ongoing_events.length);
                promoteHero.push(result.data.data.ongoing_events[random_promotedEvent_ong]);
              }
              // setTrendingEvent(result.data.data.trending_events.length > 0 ? result.data.data.trending_events : null);
              
              if (result.data.data.saved_events.length > 0) {
                let saveEventList:generalObj[] = [];
                result.data.data.saved_events.forEach((element:generalObj) => {
                  element['saved'] = true;
                  saveEventList.push(element);
                });
                setSavedEvent(saveEventList);
              }else {
                setSavedEvent(null);
              }
    
              if (result.data.data.upcoming_events.length > 0) {
                let upcomingEventList:generalObj[] = [];
                result.data.data.upcoming_events.forEach((element:generalObj) => {
                  let searchItem = result.data.data.saved_events.findIndex((evt:generalObj) => evt.id === element.id);
                  if (searchItem !== -1) {
                    element['saved'] = true;
                  }else {
                    element['saved'] = false;
                  }
                  upcomingEventList.push(element);
                });
                setUpComingEvent(upcomingEventList);
      
                let random_promotedEvent_id = Math.floor(Math.random() * result.data.data.upcoming_events.length);
                promoteHero.push(result.data.data.upcoming_events[random_promotedEvent_id]);
              }else {
                setUpComingEvent(null);
                setPromotedEvent(null);
                
              }
              if (promoteHero.length > 0) {
                let promotedEvent = Math.floor(Math.random() * promoteHero.length);
                setPromotedEvent(promoteHero[promotedEvent]);
              }else {
                setPromotedEvent({
                  cover_image: require("../assets/img/hero.gif"),
                  name: "Ticki",
                  disabled: true
                });
              }
              setItemBlock(true);
            }
        };

        // if (refreshed) {
        //     setRefreshed(false);
        // }
          loadEvents();
    },[account]);

    const refreshSavedEvent = async () => {
      if (!account) return;
      const result:generalObj = await updateEventSaved(account.token);
      if (result.status) {
        if (result.data.data.length > 0) {
          let saveEventList:generalObj[] = [];
          result.data.data.forEach((element:generalObj) => {
            element['saved'] = true;
            saveEventList.push(element);
          });
          setSavedEvent(saveEventList);
        }else {
          setSavedEvent(null);
        }
      }
    }

    const goToOnBoarding = async () => {
      await loadOnboarding();
      navigate(UrlType.KYC);
    }

    return (
      <>
        <div className={`appBody${!itemBlock ? " loading" : ""}`}>
          {/* {account && account.isAuthenticated ? ( */}
          <div className='appCont' ref={landingRef}>
              <Hero item={promotedEvent} updateSearchObject={(val) => setOpenSearch(val)} />

              {/* Search bar */}
              <div className="landingSearch" style={{position:'relative', height:"40px", marginBottom:"20px"}}>
                {itemBlock ? (
                  <div className='searchBar' onClick={() => setOpenSearch(true)}>
                      <SearchInputSvg />
                      <span className='inputText' style={{padding:"10px 8px", backgroundColor:'transparent'}}>Search events, artist, people</span>
                  </div>
                ): (
                  <div className='searchBar' style={{backgroundColor:'#7f7f7f', height:"40px"}}>
                  </div>
                )}
              </div>

              {/* Location indicator */}
              {itemBlock ? (
                <LocationSetter locationData={newLocation ? newLocation : locationData} openModal={(val) => setLocationModal(val)} />
              ): (
                <div className='locationIndicator' style={{marginBottom:"20px"}}>
                  <div className='postOwnerText' style={{backgroundColor:'#7f7f7f', width:"100px", height:"16px", borderRadius:"10px"}}></div>
                </div>
              )}

              {itemBlock ? (
                <>
                  {account && account.isAuthenticated && !account.isKyc && (
                    <div style={{margin:"0px 20px", padding:"13px", display:'flex', backgroundColor:'#1D1716', borderRadius:"12px", marginBottom:"32px"}}>
                      <div style={{width:"41.67%", display:"flex", alignItems:'center', justifyContent:'center', position:'relative'}}>
                        <img src={require('../assets/img/genre/afropop.png')} style={{width:"119px", height:"109px", borderRadius:"7px"}} alt="pan" />
                        <div style={{width:"47px", height:"37px", borderRadius:"5px", backgroundColor:"rgb(29, 23, 22)", padding:"2px", position:'absolute', right:"0px", top:"-5px"}}>
                          <img src={require('../assets/img/genre/kpop.png')} style={{width:'100%', height:'100%', borderRadius:"2px"}} alt='pan2'  />
                        </div>
                        <div style={{width:"47px", height:"37px", borderRadius:"5px", backgroundColor:"rgb(29, 23, 22)", padding:"2px", position:'absolute', left:"0px", bottom:"-5px"}}>
                          <img src={require('../assets/img/genre/pop.png')} style={{width:'100%', height:'100%', borderRadius:"2px"}} alt='pan2'  />
                        </div>
                      </div>
                      <div style={{width:"58.33%", paddingLeft:'8px'}}>
                        <p className='buttonFrameText' style={{fontSize:"16px", textAlign:'left', lineHeight:"22px", fontWeight:"600"}}>Tell us what you’re into!</p>
                        <p className='inputLabel' style={{color:'#FFF', marginTop:"5px"}}>Let us know what kind of music and events you like so we can make better recommendations for you.</p>
                        <div style={{marginTop:"12px", position:'relative'}}>
                          <button style={{width:'auto', padding:"3px"}} onClick={goToOnBoarding}>
                            <span>
                              <NextIconSmSvg />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  
                  {/* Ongoing events container */}
                  {onGoingEvent && (
                    <div className='ongoing_event_container' style={{marginBottom:"10px"}}>
                      <LandingEventCarousel parentWidth={landingRef.current ? `${landingRef.current.clientWidth}px` : "100%"} item={onGoingEvent} title={'Ongoing Events'} onSavedEvent={refreshSavedEvent} showRecommendPop={() => setRecommendPop(true)} />
                      <HomeDivider />
                    </div>
                  )}

                  {/* Upcoming events container */}
                  {upComingEvent && (
                    <div className='upcoming_event_container' style={{marginBottom:"10px"}}>
                      <LandingEventCarousel parentWidth={landingRef.current ? `${landingRef.current.clientWidth}px` : "100%"} item={upComingEvent} title={'Upcoming Events'} onSavedEvent={refreshSavedEvent} showRecommendPop={() => setRecommendPop(true)} />
                    </div>
                  )}
                  

                  {/* Saved events container */}
                  {savedEvent && (
                    <div className='saved_event_container' style={{marginBottom:"10px"}}>
                      <HomeDivider />
                      <LandingEventCarousel parentWidth={landingRef.current ? `${landingRef.current.clientWidth}px` : "100%"} item={savedEvent} title={'Saved Events'} onSavedEvent={refreshSavedEvent} showRecommendPop={() => setRecommendPop(true)} />
                    </div>
                  )}

                  {locationStateData && (
                    <LocationModal visible={locationModal}  locationData={locationStateData} modalClose={() => setLocationModal(false)} newLocation={(val) => {setNewLocation(val); setLocationModal(false);}} />
                  )}

                  {/* <RecommendModal visible={recommendPop} modalClose={()=>setRecommendPop(false)} /> */}
                  
                </>
              ): (
                <div style={{margin:"0px 20px", marginBottom:"10px"}}>
                    <div className='postOwnerText' style={{backgroundColor:'#7f7f7f', height:180, borderRadius:10}}></div>
                </div>
              )}
          </div>
          {/* ): (
            <div>
              <h4>Landing page - un-Authenticated</h4>
              <a href={UrlType.LOGIN}>Login Page</a>
            </div>
          )} */}
          <Footer />
        </div>
          
        <BottomTab location_path={location.pathname} />
        <SearchModal visible={openSearch} modalClose={() => setOpenSearch(false)} />
      </>
    )
}

export default Landing;