import { useEffect, useRef, useState } from "react";
import {IoEllipse, IoEllipseSharp, IoPlay } from "react-icons/io5";
import ProfileGenerator from "../ProfileGenerator";
import { EditIconSvg, RegularIconSvg, TicketSmallSvg, VipIconSvg, VvipIconSvg } from "../../styles/IconStyle";
import { generalObj } from "../../constant/objects_types";
import { altCalculateSellingPrice, calculateSellingPrice, createEvent, getBlobUri, getDateStr, getTimeStr, processEventData, updateEvent } from "../../action/generalFunc";
import ButtonFrame from "../ButtonFrame";
import BorderDivider from "../BorderDivider";
import SellPriceModal from "../modal/SellPriceModal";
import ModalLoader from "../modal/ModalLoader";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import EventComplete from "./EventComplete";
import { useParams } from "react-router-dom";
import ChangeReviewPrice from "./ChangeReviewPrice";
import PreviewEvent from "./PreviewEvent";
import { SYMBOL } from "../../constant/constant";
import ReactPlayer from "react-player";

const ReviewEvent = ({ visible, eventObj, isEditting, modalClose, onChange, parentRef }:{
    visible:boolean,
    eventObj:generalObj[],
    isEditting: boolean,
    modalClose:() => void,
    onChange: (name:string, val:any) => void,
    parentRef: generalObj
}) => {
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [reviewEvent, setReviewEvent] = useState<generalObj>({});
    const [reviewEventP, setReviewEventP] = useState<generalObj>({});
    const [reviewEventT, setReviewEventT] = useState<generalObj>({});
    const [sellingDetail, setSellingDetail] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [completeEvent, setCompleteEvent] = useState<boolean>(false);
    const [openPreview, setOpenPreview] = useState<boolean>(false);
    const assetList:generalObj[] = [
        {
            id:'1',
            active:true
        },{
            id:'2',
            active:true
        }
    ];
    const assetListRef = useRef<HTMLDivElement | null>(null);
    const videoRef = useRef<HTMLVideoElement | null>(null);

    const [assetImg, setAssetImg] = useState<boolean>(true);
    const [ticketForUpdate, setTicketForUpdate] = useState<generalObj | null>(null);
    const [openTicketEdit, setOpenTicketEdit] = useState<boolean>(false);
    const [touchStart, setTouchStart] = useState<number>(0);

    const account = useSelector((state:RootState) => state.account);
    const { eventId } = useParams();

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!videoRef || !videoRef.current) return;
        if (assetImg) {
            videoRef.current.pause();
        }else {
            videoRef.current.play();
        }
    }, [assetImg])

    useEffect(() => {
        if (!eventObj || !eventObj.length) return;
        setReviewEvent({...eventObj[0].fields});
        setReviewEventP({...eventObj[1].fields});
        setReviewEventT({...eventObj[2].fields});
    }, [eventObj])

    const getBasePrice = () => {
        if (reviewEventT.eventHasTicket) {
            if (reviewEventT.ticketCategory) {
                let total_price:Array<number> = [];
                reviewEventT.categories.forEach((element:generalObj) => {
                    if (!element.isDeleted) {
                        total_price.push(element.price);
                    }
                });
                let min_price = Math.min(...total_price);
                if (min_price > 0)  {
                    return `${SYMBOL} ${reviewEventT.UsersIncurCharge ? calculateSellingPrice(min_price) : parseFloat(min_price.toString()).toLocaleString()}`
                }else {
                    return "Free";
                }
            }else {
                if (reviewEventT.noCategory.price > 0)  {
                    return `${SYMBOL} ${reviewEventT.UsersIncurCharge ? calculateSellingPrice(parseFloat(reviewEventT.noCategory.price)) : parseFloat(reviewEventT.noCategory.price).toLocaleString()}`
                }else {
                    return "Free";
                }
            }
        }else {
            return "Free";
        }
    }

    const ticketPriceEdit = (pObj:generalObj) => {
        setTicketForUpdate(pObj);
        setOpenTicketEdit(true);
    }

    const submitEventCreation = async () => {
        if (!account) return;
        setModalLoading(true);
        const process_data:generalObj = await processEventData(reviewEvent, reviewEventP, reviewEventT);
        
        if (!process_data.error) {
            const createEvt = isEditting ? await updateEvent(process_data.data, account.token, eventId ? eventId : ""): await createEvent(process_data.data, account.token);
            
            if (createEvt && !createEvt.error) {
                //navigation.navigate('Event_Complete');
                setCompleteEvent(true);
            }else {
                alert(createEvt?.message);
            }
        }else {
            alert(process_data.message);
        }
        setModalLoading(false);
    }

    const eventTicketPriceUpdate = (val:generalObj) => {
        if (reviewEventT.ticketCategory) {
            let categories = reviewEventT.categories.filter((item:generalObj) => {
                if (item.id === val.id) {
                    item = val
                }
                return item
            });
            onChange('categories', categories);
        }
        else {
            onChange('noCategory', val);
        }
        setOpenTicketEdit(false); 
        setTicketForUpdate(null);
    }

    const handleScroll = (evt:generalObj, deltaX:number=0) => {
        if (!reviewEventP || (!reviewEventP.promotionVideo && !reviewEventP.promotionVideoUrl)) return;
        const parentElem = evt.target.parentElement;
        if (parentElem.id === "imgID" && (evt.deltaX > 0 || deltaX < 0)) {
            handleSwitch("#review_asset__2", false)
        }else if (parentElem.id !== "imgID" && (evt.deltaX < 0 || deltaX > 0)) {
            handleSwitch("#review_asset__1", true)
        }
    }

    const handleSwitch = (target:string, val:boolean) => {
        if (!assetListRef || !assetListRef.current) return;
        assetListRef.current.querySelector(target)?.scrollIntoView({behavior:'smooth'});
        setAssetImg(val);
    }

    const handleTouchStart = (e:generalObj) => {
        setTouchStart(e.touches[0].clientX);
    }


    const handleTouchMove = (e:generalObj) => {
        let deltaX = e.touches[0].clientX - touchStart;
        handleScroll(e, deltaX)
    }

    return (
        <>
            {firstShow && (
                <>
                    <div className={`create__event__review modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%', position:'relative'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"20px", marginBottom:"20px"}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Event Summary</div>
                                    </div>
                                </div>
                                <div style={{width:'100%', height: "calc(100% - 80px)", overflowX: "hidden"}}>
                                    <div className="listContainer" style={{paddingBottom:"120px", margin:0}}>
                                        <div style={{width:'100%'}}>
                                            <div className="review_asset__container" ref={assetListRef} onWheel={(e) => handleScroll(e)} onTouchStart={(e) => handleTouchStart(e)} onTouchMove={(e) => handleTouchMove(e)}>
                                                {assetList.map((item, index) => (
                                                    <div key={index} className="review_asset__item" id={`review_asset__${item.id}`}>
                                                        {item.id === '1' && (
                                                            <>
                                                                {reviewEventP && (reviewEventP.coverImage || reviewEventP.coverImageUrl) && (
                                                                    <div className="displayImageContainer" id="imgID" style={{width:"100%", height:`${(parentRef.clientWidth) / (4/3)}`}}>
                                                                        <img src={reviewEventP.coverImage ? getBlobUri(reviewEventP.coverImage) : reviewEventP.coverImageUrl} className="displayImage" style={{objectFit:'cover'}} alt="cover"  />
                                                                    </div> 
                                                                )}
                                                            </>
                                                        )}
                                                        {item.id === '2' && (
                                                            <>
                                                                {reviewEventP && (reviewEventP.promotionVideo || reviewEventP.promotionVideoUrl) && (
                                                                    <div className="displayImageContainer" id="videoID" style={{width:"100%", height:`${(parentRef.clientWidth) / (4/3)}`}}>
                                                                        {/* <video 
                                                                            ref={videoRef}
                                                                            className="displayImage"
                                                                            style={{width:'100%',height:'100%', borderRadius:"12px", objectFit:'contain'}}
                                                                            src={reviewEventP.promotionVideo ? getBlobUri(reviewEventP.promotionVideo) : reviewEventP.promotionVideoUrl}
                                                                            loop={true}
                                                                        /> */}
                                                                        <ReactPlayer url={reviewEventP.promotionVideo ? getBlobUri(reviewEventP.promotionVideo) : reviewEventP.promotionVideoUrl} style={{width:'100%',height:'100%', borderRadius:"12px", objectFit:"contain"}}  playing={true} loop={true} muted={true} playsinline={true} />
                                                                    </div> 
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                            {reviewEventP && (reviewEventP.promotionVideo || reviewEventP.promotionVideoUrl) && (
                                                <div style={{display:'flex', marginTop:"10px", justifyContent:'center', alignItems:'center',gap:"7px"}}>
                                                    <button onClick={()=>handleSwitch("#review_asset__1", true)}>
                                                        <IoEllipse size={"10px"} color={assetImg?'#FFFFFF':'rgba(255, 255, 255, 0.12)'} />
                                                    </button>
                                                    <button onClick={()=>handleSwitch("#review_asset__2", false)}>
                                                        <IoEllipse size={"10px"} color={!assetImg?'#FFFFFF':'rgba(255, 255, 255, 0.12)'} />
                                                    </button>
                                                </div>
                                            )}
                                            
                                            <div>
                                                <div className="sectionDisplay" style={{marginTop: "10px"}}>
                                                    <div style={{display:'flex', alignItems:'center', gap:"5px"}}>
                                                        <ProfileGenerator constClass="profilePictureUser" constStyle={{width:"24px", height:"24px", borderRadius:"24px"}} textStyle={{fontSize:"13px", lineHeight:"17px"}} useAccount={true} />
                                                        <div className="itemOwner">{account && account.username}</div>
                                                        <div>
                                                            <TicketSmallSvg />
                                                        </div>
                                                    </div>
                                                </div>

                                                {reviewEvent && reviewEvent.name && (
                                                    <div className="sectionDisplay" style={{marginTop:0}}>
                                                        <div>
                                                            
                                                            <div className="itemOwner" style={{marginTop:"10px", fontSize:"16px", lineHeight:"16px"}}>{reviewEvent.name}</div>
                                                            <div className="itemOwner" style={{marginTop:"8px", fontSize:"16px"}}>{getBasePrice()}</div>
                                                            <div style={{display:'flex', marginTop:"8px"}}>
                                                                <div className="itemOwner bodyText" style={{lineHeight:"13px", color:'#9E9B9B'}}>{reviewEvent.date.oneTime?getDateStr(reviewEvent.oneTime.startDate, true) : getDateStr(reviewEvent.recurring.startDate, true)}</div>
                                                                <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                                                    <IoEllipseSharp size={"4px"} color="#9E9B9B" style={{marginInline:"5px"}} />
                                                                </div>
                                                                <div className="itemOwner bodyText" style={{lineHeight:"13px", color:'#9E9B9B'}}>{reviewEvent.date.oneTime?getTimeStr(reviewEvent.oneTime.startDate) : getTimeStr(reviewEvent.recurring.startDate)}</div>
                                                            </div>

                                                            <div className="itemOwner bodyText" style={{marginTop:"8px", lineHeight:"13px", color:'#9E9B9B'}}>{reviewEvent.location.online?'Online':reviewEvent.venue_location.addressLabel}</div>
                                                        </div>
                                                    </div>
                                                )}

                                                <button style={{marginTop:"27px", display:'flex', justifyContent:'center', alignItems:'center', marginLeft: "auto", marginRight: "auto"}} onClick={()=>setOpenPreview(true)}>
                                                    <IoPlay size={"12px"} color={"#DB012E"} />
                                                    <div className="sectionTopLink" style={{fontWeight:'600', fontSize:"13px", lineHeight:"13px", marginLeft:"5px"}}>Preview your Event</div>
                                                </button>
                                                <div>
                                                    <BorderDivider borderStyle={{marginTop:"28px", marginBottom:"32px"}} />
                                                </div>
                                                {reviewEventT.eventHasTicket && (
                                                    <div>
                                                        <div className="itemOwner" style={{fontWeight:'700', marginBottom:"16px"}}>Ticket Categories</div>
                                                        {reviewEventT && reviewEventT.ticketCategory && (
                                                            reviewEventT.categories.map((item:generalObj, index:number) => (
                                                                !item.isDeleted && (
                                                                    <div key={index} style={{marginTop:index===0?0:"32px"}}>
                                                                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                            <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                                                {item.title === 'V.V.I.P' && <VvipIconSvg />}
                                                                                {item.title === 'V.I.P' && <VipIconSvg />}
                                                                                {(item.title === 'Regular' || (item.title !== 'V.V.I.P' && item.title !== 'V.I.P')) && <RegularIconSvg />}
                                                                                <div className="itemOwner" style={{fontWeight:'700', marginLeft:5}}>{item.title}</div>
                                                                            </div>
                                                                            <button onClick={() => ticketPriceEdit(item)}>
                                                                                <EditIconSvg />
                                                                            </button>
                                                                        </div>


                                                                        <div className="itemOwner" style={{fontWeight:'600', marginBlock:"8px"}}>{SYMBOL}{parseInt(item.price).toLocaleString()}</div>
                                                                        <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                            <div className="itemOwner bodyText" style={{fontWeight:'600', color:"#9E9B9B"}}>{reviewEventT.UsersIncurCharge ? 'Selling Price' : 'You will receive'}: {SYMBOL}{reviewEventT.UsersIncurCharge ? calculateSellingPrice(item.price) : altCalculateSellingPrice(item.price)}</div>
                                                                            <button onClick={()=>setSellingDetail(true)}>
                                                                                <span className="sectionTopLink" style={{fontWeight:'500', fontSize:"13px", lineHeight:"16px"}}>More</span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            ))
                                                        )}
                                                        {sellingDetail && <SellPriceModal visible={sellingDetail} modalClose={()=>setSellingDetail(false)} /> }

                                                        {reviewEventT && !reviewEventT.ticketCategory && (
                                                            <div>
                                                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                    <div style={{display:'flex', justifyContent:'center', gap:'5px', alignItems:'center'}}>
                                                                        <RegularIconSvg />
                                                                        <div className="itemOwner" style={{fontWeight:'600'}}>{reviewEventT.noCategory.title ? reviewEventT.noCategory.title : 'Regular'}</div>
                                                                    </div>
                                                                    
                                                                    <button onClick={() => ticketPriceEdit(reviewEventT.noCategory)}>
                                                                        <EditIconSvg />
                                                                    </button>
                                                                </div>
                                                                <div className="itemOwner" style={{fontWeight:'600', marginBlock:"8px"}}>{SYMBOL}{parseInt(reviewEventT.noCategory.price).toLocaleString()}</div>
                                                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                    <div className="itemOwner bodyText" style={{fontWeight:'600', color:"#9E9B9B"}}>{reviewEventT.UsersIncurCharge ? 'Selling Price' : 'You will receive'}: {SYMBOL}{reviewEventT.UsersIncurCharge ? calculateSellingPrice(reviewEventT.noCategory.price) : altCalculateSellingPrice(reviewEventT.noCategory.price)}</div>
                                                                    <button onClick={()=>setSellingDetail(true)}>
                                                                        <span className="sectionTopLink" style={{fontWeight:'500', fontSize:"13px", lineHeight:"16px"}}>More</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )}


                                                        
                                                    </div>
                                                )}

                                                {!reviewEventT.eventHasTicket && (
                                                    <div>
                                                        <div className="itemOwner" style={{fontWeight:'700', marginBottom:"16px"}}>Ticket Categories</div>
                                                        
                                                        <div>
                                                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                                                                    <RegularIconSvg />
                                                                    <div className="itemOwner" style={{fontWeight:'600'}}>Regular</div>
                                                                </div>
                                                                <div>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="itemOwner" style={{fontWeight:'600', marginBlock:"8px"}}>Free</div>
                                                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                                <div className="itemOwner bodyText" style={{fontWeight:'600'}}>Selling Price: Free</div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="review__action" style={{width:"100%",position:'absolute',bottom:"0px", backgroundColor:"#0D0404", paddingBottom: "25px"}}>
                                    <div style={{width: "100%", margin:"0px auto"}}>
                                    <ButtonFrame title={isEditting ? "Update Event" : "Create Event"} contStyle={{backgroundColor:'#DB012E', borderWidth:0}} onPress={submitEventCreation} />
                                    <ButtonFrame title="Go back" contStyle={{marginTop: 12, backgroundColor:'#4F4F4F', borderWidth:0}} onPress={() => modalClose()} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <PreviewEvent 
                visible={openPreview}
                itemObj={{
                    reviewEvent: reviewEvent,
                    reviewEventP: reviewEventP,
                    reviewEventT: reviewEventT
                }}
                modalClose={() => setOpenPreview(false)}
                eventSubmit={() => submitEventCreation()}
            />
            <ModalLoader visible={modalLoading} />
            <EventComplete visible={completeEvent} isEditting={isEditting} />
            {ticketForUpdate && <ChangeReviewPrice visible={openTicketEdit} itemObj={ticketForUpdate} modalClose={() => {setOpenTicketEdit(false); setTicketForUpdate(null);}} onChange={(val) => eventTicketPriceUpdate(val)} />}
        </>
    )
}

export default ReviewEvent;