import { useState, useEffect } from "react";
const MoneyInfo = ({ visible, modalClose }:{
    visible:boolean,
    modalClose:() => void
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    
    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    return (
        <>
            {firstShow && (
                <div className={`money__info modalParentContainer${!visible ? " animate__animated animate__fadeOut" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"transparent", zIndex:`${visible ? "9999" : "0"}`}}>
                    <div className="modalContainer" style={{overflow:'hidden'}}>

                    <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>
                    <div className={`actionView moneyInfo${!visible ? " animate__animated animate__slideOut" : " animate__animated animate__zoomIn" }`} style={{height:"auto", width:"calc(100% - 80px)", borderRadius:'14px', padding:'22px 24px'}}>
                        <div className={"detailTitle"} style={{textAlign:'center'}}>My Money</div>
                        <div className="detailSection">
                            <div className="detailDesc detailDescPrice" style={{textAlign:'center'}}>This page shows you the amount of money you’ve made from all your events.</div>
                        </div>
                        <div className="detailSection">
                            <div className="detailDesc detailDescPrice" style={{textAlign:'center'}}>You can withdraw the money made from all events or you can select the event you want to withdraw money from.</div>
                        </div>
                        <div className="detailSection">
                            <div className="detailDesc detailDescPrice" style={{textAlign:'center'}}>Withdrawals are only allowed on Monday and Friday of every week</div>
                        </div>
                        <div className="detailSection">
                            <div className="detailDesc detailDescPrice" style={{textAlign:'center'}}>The money goes to the bank account you provide within 1 -  2 days.</div>
                        </div>
                        <div style={{marginTop:"16px", textAlign:'center'}}>
                            <button onClick={modalClose}>
                                <span className="sectionTopLink" style={{lineHeight:"16px", fontSize:'13px'}}>Got it!</span>
                            </button>
                        </div>
                    </div>
                    
                    </div>
                </div>
            )}
        </>
    )
}

export default MoneyInfo;