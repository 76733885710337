import { useEffect, useState, useRef } from "react";
import { BackArrow, RegisterContent } from "../../components";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RootState, ractionCreator } from "../../redux";
import { useNavigate } from "react-router-dom";
import { UrlType } from "../../constant/objects_types";
import { IoCloseOutline } from "react-icons/io5";

const RegisterStepTwo = () => {
    const resgisterState = useSelector((state:RootState) => state.registerInfo);
    const [fullname, setFullName] = useState<string>('');
    const [nameErr, setNameErr] = useState<boolean>(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { updateInfo } = bindActionCreators(ractionCreator, dispatch);
    const nameRef = useRef<HTMLInputElement | null>();

    useEffect(() => {
        if (!resgisterState || !resgisterState.email) {
            navigate(UrlType.REGISTER_STEP1);
            return;
        }
        if (!resgisterState.fullname) return;
        setFullName(resgisterState.fullname);
    }, [resgisterState, navigate]);

    const checkName = () => {
        if (fullname.length === 0) return;
        if (fullname.length >= 4) {
          setNameErr(false);
          return true;
        }else {
          setNameErr(true);
          return false;
        }
    }

    const onSubmit = async () => {
        if (fullname.length === 0) return;
        if (checkName()) { 
            await updateInfo("fullname", fullname);
            navigate(UrlType.REGISTER_STEP3);
        }
    }


    return (
        <>
            <div className='registerAuth authContainer' style={{justifyContent:'flex-start'}}>
                <BackArrow onPress={() => {window.history.back()}} />
                <div className="register">
                    <RegisterContent 
                        header="What’s your name?" 
                        btnTitle="Next" 
                        id={'fullname'}
                        description="Help people discover your account by using the name you’re known by. It could be your full name, business name or nickname." 
                        inputProp={{value:fullname, text:'text', maxLength:'20', onBlur:checkName}}
                        refInput={(input)=>nameRef.current = input}
                        isIcon={<IoCloseOutline size={"24px"} color="#FFF" /> } 
                        onPress={onSubmit}
                        btnStyle={!nameErr && fullname.length >= 4 ? {opacity:'1', cursor:"pointer"} : {cursor:'not-allowed'}}
                        onTextChange={(val)=>{setFullName(val); setNameErr(false)}}
                        iconPress={() => {setFullName(''); setNameErr(false)}}
                        message={nameErr ? {
                            style: {
                                color: '#F00D35'
                            },
                            show: nameErr,
                            text: "Your name must be above 4 characters"
                        }: {}}
                    />
                </div>
            </div>
        </>
    )
}

export default RegisterStepTwo;