import { InfoIconSmallSvg, LogoutIconSmallSvg, MoneyIconSmallSvg, PaymentIconSmallSvg, QuestionIconSmallSvg, ShareSvg, UserIconSmallSvg } from "../../styles/IconStyle"

const HamMeunModal = ({ visible, modalClose, actionPressed }:{
    visible:boolean,
    modalClose: () => void,
    actionPressed: (val:string) => void,
}) => (
    <div className={`ham__menu modalParentContainer${!visible ? " d-none" : " animate__animated animate__fadeIn" }`} style={{backgroundColor:"rgba(0, 0, 0, 0.70)", zIndex:"9999"}}>
            <div className="modalContainer flexEnd" style={{overflow:'hidden'}}>
                <div style={{position:'absolute', width:'100%', height:"100%"}} onClick={modalClose}></div>

                <div className="actionView animate__animated animate__slideInUp" style={{height:"auto"}}>
                    <div style={{margin: "20px 10px 22px", position: "relative", height: "100%"}}>
                        <div className="actionItemCont" style={{position:'relative', height:'auto'}}>
                            <button className="actionItem ham" onClick={()=>actionPressed("Share")}>
                                <ShareSvg />
                                <span className="">Share</span>
                            </button>

                            <button className="actionItem ham" onClick={()=>actionPressed("ManageAccount")}>
                                <UserIconSmallSvg />
                                <span className="">Manage Account</span>
                            </button>

                            <button className="actionItem ham" onClick={()=>actionPressed("MyMoney")}>
                                <MoneyIconSmallSvg />
                                <span className="">My Money</span>
                            </button>

                            <button className="actionItem ham" onClick={()=>actionPressed("PaymentAccount")}>
                                <PaymentIconSmallSvg />
                                <span className="">Payment Accounts</span>
                            </button>

                            <button className="actionItem ham" onClick={()=>actionPressed("HelpCenter")}>
                                <QuestionIconSmallSvg />
                                <span className="">Help Center</span>
                            </button>

                            <button className="actionItem ham" onClick={()=>actionPressed("AboutUs")}>
                                <InfoIconSmallSvg />
                                <span className="">About Us</span>
                            </button>

                            <button className="actionItem ham" onClick={()=>actionPressed("Logout")}>
                                <LogoutIconSmallSvg />
                                <span className="">Log Out</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
    </div>
)

export default HamMeunModal;