import { useState, useEffect } from "react";
import BackArrow from "../BackArrow";
import { UserCircleSmallIconSvg, NotificationSmallSvg } from "../../styles/IconStyle";
import { IoChevronForwardOutline } from "react-icons/io5";
import { generalObj } from "../../constant/objects_types";
import PersonalInfoList from "./PersonalInfo";
import NotificationInfoList from "./NotificationInfo";

const ManageAccount = ({ visible, profileInfo, modalClose }:{
    visible : boolean,
    profileInfo: generalObj,
    modalClose: () => void,
}) => {
    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [openPersonalInfo, setOpenPersonalInfo] = useState<boolean>(false);
    const [openNotifcationInfo, setOpenNotifcationInfo] = useState<boolean>(false);

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    return (
        <>
            {firstShow && (
                <>
                    <div className={`manage__account modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                        <div className="modalContainer" style={{}}>
                            <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                        <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Manage Account</div>
                                    </div>
                                </div>
                                
                                <div style={{width:'100%', marginTop:"24px", height:"100%"}}>
                                    <div className={"inputField"} onClick={() => setOpenPersonalInfo(true)}>
                                        <div className="inputTextCont" style={{borderBottom: "1px solid rgba(255, 255, 255, 0.1)"}}>
                                            <div className="inputText" style={{paddingLeft:"0px", paddingRight:"0px",backgroundColor: 'transparent', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                <div style={{display:"flex", flexDirection:'row', alignItems:'center'}}>
                                                    <div style={{display:'flex'}}>
                                                        <UserCircleSmallIconSvg />
                                                    </div>
                                                    <div style={{fontWeight:'500', fontSize:"14px", lineHeight:"22px", marginLeft:"5px", color:'#FFF'}}>Personal Information</div>
                                                </div>
                                                <div style={{display:'flex', alignItems:'flex-end'}}>
                                                    <IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"inputField"} onClick={() => setOpenNotifcationInfo(true)}>
                                        <div className="inputTextCont" style={{borderBottom: "1px solid rgba(255, 255, 255, 0.1)"}}>
                                            <div className="inputText" style={{paddingLeft:"0px", paddingRight:"0px",backgroundColor: 'transparent', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                                <div style={{display:"flex", flexDirection:'row', alignItems:'center'}}>
                                                    <div style={{display:'flex'}}>
                                                        <NotificationSmallSvg />
                                                    </div>
                                                    <div style={{fontWeight:'500', fontSize:"14px", lineHeight:"22px", marginLeft:"5px", color:'#FFF'}}>Notification</div>
                                                </div>
                                                <div style={{display:'flex', alignItems:'flex-end'}}>
                                                    <IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            
            {profileInfo && (
                <>
                    <PersonalInfoList visible={openPersonalInfo} profileInfoItem={profileInfo} modalClose={() => setOpenPersonalInfo(false)} />
                    <NotificationInfoList visible={openNotifcationInfo} profileInfoItem={profileInfo} modalClose={() => setOpenNotifcationInfo(false)} />
                </>
            )}
        </>
    )
}

export default ManageAccount;