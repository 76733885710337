import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { BackArrow, ButtonFrame, InputField, ModalLoader, NavBar, ResetComplete } from "../../components";
import { useEffect, useRef, useState } from "react";
import { resetPassword, validatePassword } from "../../action/generalFunc";
import { UrlType, generalObj } from "../../constant/objects_types";
import { useNavigate, useParams } from "react-router-dom";
import { FilledRadioSvg, UnfilledRadioSvg } from "../../styles/IconStyle";

const ResetPassword = () => {
    const [resetData, setResetData] = useState<generalObj>({
        password: '',
        cnfPassword: '',
        secureEntry_p: true,
        secureEntry_c: true
    });
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [showDetail, setShowDetail] = useState<boolean>(false);
    const passwordRef = useRef<HTMLInputElement | null>(null);
    const cnfpasswordRef = useRef<HTMLInputElement | null>(null);
    const [passwordCheckList, setPasswordCheckList] = useState<generalObj>([
        {
          id:'1',
          text: "Includes uppercase and lower letter",
          checked: false,
          identifier: 'isUpper',
          color: '#FFF'
        },{
          id:'2',
          text: "Includes at least one special character (!,*,#,%...)",
          checked: false,
          identifier: 'isSpecial',
          color: '#FFF'
        },{
          id:'3',
          text: "Must be 8 characters or longer",
          checked: false,
          identifier: 'isLength',
          color: '#FFF'
        },{
          id:'4',
          text: "Includes at least one number",
          checked: false,
          identifier: 'isNumber',
          color: '#FFF'
        }
    ])
    const [passErr, setPassErr] = useState<boolean>(false);
    const navigate = useNavigate();

    const { id } = useParams();

    useEffect(() => {
        if (id) return;
        alert("Reset token required");
        navigate(UrlType.LOGIN);
    }, [id, navigate]);

    const checkPassword = () => {
        let result = validatePassword(resetData.password);
        setPassErr(!result);
        return result;
    }

    const setTextChange = (val:string) => {
        let result:generalObj = validatePassword(val);
        setResetData({...resetData, password:val})
        setPasswordCheckList((prevPasswordCheckList) => {
          return (
            prevPasswordCheckList.filter((item:generalObj) => {
              item.checked = result[item.identifier];
              item.color = '#FFF';
              return item
            })
          )
        })
        let valueList = Object.values(result);
        setPassErr(valueList.includes(false));
    }

    const submitPasswordReset = async () => {
        if (!id) return;

        if (passErr) {
            alert("New password doesn't meet Ticki's standard");
            return;
        }
        if(resetData.password !== resetData.cnfPassword) {
            alert("Passwords doesn't match");
            return;
        }
        setModalLoading(true);
        const data = {
            token: id,
            password: resetData.password
        }
        console.log(data);
        let result = await resetPassword(data);
        if (result) {
            setShowDetail(true);
        }
        setModalLoading(false);
    }

    return (
        <>
            <div className='authNav'>
                <NavBar updateOpenSearchModal={() => null} />
            </div>
            <div className='registerAuth fp step1 authContainer' style={{justifyContent:'flex-start'}}>
                <BackArrow onPress={() => window.history.back()} />

                <h3 className="fp_header">Reset Password?</h3>
                
                <InputField 
                    id={"password"} 
                    label={"New Password"} 
                    refInput={(input) => passwordRef.current = input} 
                    customInputField={{marginTop:"15px"}} 
                    inputProp={{value:resetData.password, type:resetData.secureEntry_p ? "password" : "text", onBlur:checkPassword }} 
                    isIcon={resetData.secureEntry_p ? <IoEyeOffOutline size={"16px"} color="rgba(255, 255, 255, 0.6)" /> :<IoEyeOutline size={"16px"} color="rgba(255, 255, 255, 0.6)" /> } 
                    iconPress={() => setResetData({...resetData, secureEntry_p:!resetData.secureEntry_p})}
                    onTextChange={(val)=> setTextChange(val)} 
                />


                <InputField 
                    id={"cnf_password"} 
                    label={"Confirm Password"} 
                    refInput={(input) => cnfpasswordRef.current = input} 
                    customInputField={{marginTop:"15px"}} 
                    inputProp={{value:resetData.cnfPassword, type:resetData.secureEntry_c ? "password" : "text", required:true }} 
                    isIcon={resetData.secureEntry_c ? <IoEyeOffOutline size={"16px"} color="rgba(255, 255, 255, 0.6)" /> :<IoEyeOutline size={"16px"} color="rgba(255, 255, 255, 0.6)" /> } 
                    iconPress={() => setResetData({...resetData, secureEntry_c:!resetData.secureEntry_c})}
                    onTextChange={(val)=> setResetData({...resetData, cnfPassword:val})} 
                />

                <div style={{width:'100%', marginTop:57}}>
                    {passwordCheckList.map((item:generalObj, index:number) => (
                        <div key={index} style={{display:'flex', justifyContent:'flex-start', alignItems:'center', marginTop:index === 0?"0px":"8px"}}>
                            <span>{item.checked ? <FilledRadioSvg /> : <UnfilledRadioSvg /> }</span>
                            <span className="inputLabel" style={{lineHeight:"19px", color:item.checked ? '#FFF' : item.color, marginLeft:"8px"}}>{item.text}</span>
                        </div>
                    ))}
                </div>

                <ButtonFrame title={"Done"} contStyle={{position:'absolute', backgroundColor:'#DB012E', border:"none", bottom:"36px", width: "calc(100% - 40px)"}} onPress={submitPasswordReset} />

                <ModalLoader visible={modalLoading} />
                <ResetComplete visible={showDetail} modalClose={() => setShowDetail(false)} />
            </div>
        </>
    )
}

export default ResetPassword;