import { useEffect, useRef, useState } from "react";
import { AccountModal, BottomTab, CompanyInfo, HamMeunModal, ModalLoader, MoneyInfo, NavBar, SearchModal, ShareModal } from "../components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, actionCreator } from "../redux";
import { UrlType } from "../constant/objects_types";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

const Profile = () => {
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openManageAccount, setOpenManageAccount] = useState<boolean>(false);
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [openMoneyInfo, setOpenMoneyInfo] = useState<boolean>(false);
  const [openWithdrawComplete, setOpenWithdrawComplete] = useState<boolean>(false);
  const [eventWithdraw, setEventWithdraw] = useState<number | null>(null);
  const [openAboutUs, setOpenAboutUs] = useState<boolean>(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState<number>(0);
  //const [paymentAccount, setPaymentAccount] = useState<generalObj | null>(null);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  
  const parentRef = useRef<HTMLDivElement | null>(null);
  const account = useSelector((state:RootState) => state.account);
  const dispatch = useDispatch();
  const { logout } = bindActionCreators(actionCreator, dispatch);
  const navigate = useNavigate();

  useEffect(() => {
    if (account && account.isAuthenticated) return;
    navigate(`${UrlType.LOGIN}?redirect=${UrlType.PROFILE_USER}me`);
  }, [account, navigate]);

  const processHamSelection = async (option:string, val:boolean) => {
    switch(option) {
      case "Share":
        setOpenShare(true);
        break;
      case "ManageAccount":
        setOpenMenu(false);
        setEditProfile(val);
        setOpenManageAccount(val);
        break;
      case "MyMoney":
        setOpenMenu(false);
        navigate(UrlType.PROFILE_MY_MONEY);
        break;
      case "PaymentAccount":
        setOpenMenu(false);
        navigate(UrlType.PROFILE_ACCOUNTS);
        break;
      case "HelpCenter":
        setOpenMenu(false);
        navigate(UrlType.HELP_CENTER);
        break;
      case "AboutUs":
        setOpenMenu(false);
        setEditProfile(val);
        setOpenAboutUs(val);
        break;
      case "Logout":
        setModalLoading(true);
        await logout();
        setOpenMenu(false);
        setModalLoading(false);
        break;
      default:
        return;
    }
  }


  return (
    <>
      <MoneyInfo visible={openMoneyInfo} modalClose={() => setOpenMoneyInfo(false)} />
      <AccountModal visible={openModal} event_id={eventWithdraw} withdrawAmount={withdrawalAmount} onDone={(val) => {
        //setPaymentAccount(val);
        setModalLoading(true);
        setTimeout(() => {
          setModalLoading(false);
          setOpenModal(false);
          setOpenWithdrawComplete(true);
          setEventWithdraw(null);
        }, 3000);
      }} modalClose={()=>setOpenModal(false)} />
      <NavBar updateOpenSearchModal={() => setOpenSearch(true)} />
      <div className={`profile__container appBody ${openMenu || editProfile || openMoneyInfo ? "loading" : ""}`} style={{position:'relative'}} ref={parentRef}>
        
        <Outlet context={{ parentRef, setOpenMenu, setEditProfile, setModalLoading, openManageAccount, setOpenManageAccount, setOpenMoneyInfo, setOpenModal, setEventWithdraw, openWithdrawComplete, setOpenWithdrawComplete, setWithdrawalAmount }} />

      </div>
        
        <BottomTab location_path={location.pathname} />
        <HamMeunModal visible={openMenu} modalClose={() => setOpenMenu(false)} actionPressed={(val) => processHamSelection(val, true)} />
        <ShareModal visible={openShare} modalClose={() => setOpenShare(false)} />
        <CompanyInfo visible={openAboutUs} modalClose={() => processHamSelection("AboutUs", false)} />
        <ModalLoader visible={modalLoading} />
        <SearchModal visible={openSearch} modalClose={() => setOpenSearch(false)} />
    </>
  )
}

export default Profile;