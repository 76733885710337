import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import ModalLoader from './ModalLoader';
import BackArrow from '../BackArrow';
import { RedScannerSvg } from '../../styles/IconStyle';
import SearchBar from '../SearchBar';
import { generalObj } from '../../constant/objects_types';
import { useParams } from 'react-router-dom';
import { fetchEventUserTickets } from '../../action/generalFunc';
import AttendeeInfoModal from './AttendeeInfoModal';

const CheckInModal = ({ visible, event, isRefresh, modalClose, openBarcode, toggleRefresh }:{
    visible: boolean,
    event: generalObj,
    isRefresh: boolean,
    modalClose: () => void,
    openBarcode: () => void,
    toggleRefresh: () => void
}) => {

    const [firstShow, setFirstShow] = useState<boolean>(false);
    const [modalLoader, setModalLoader] = useState<boolean>(false);
    const [attendee, setAttendee] = useState<generalObj | null>(null);
    const [openAttendee, setOpenAttendee] = useState<boolean>(false);
    const [searchInput, setSearchInput] = useState<string>('');
    const account = useSelector((state:RootState) => state.account);
    const [tickets, setTickets] = useState<generalObj[] | null>(null);
    const parentRef = useRef<HTMLDivElement | null>(null);
    const [tableHead, setTableHead] = useState<generalObj[]>([
        {
            id: 1,
            title: 'Not Checked',
            active: true
        },{
            id: 2,
            title: 'Checked In',
            active: false
        }
    ]);
    const [pageMeta, setPageMeta] = useState<generalObj|null>(null);
    const { id } = useParams()

    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    useEffect(() => {
        if (!visible || !firstShow || !isRefresh) return;
        if (!account || !account.isAuthenticated || !id) return;
        (async () => {
            setModalLoader(true);
            let result:generalObj = await fetchEventUserTickets(account.token, id, 1, 100); 
            if (result.status) {
                let updateTickets = result.data.data.filter((item:generalObj) => {
                    if (item.used === 0) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    item.user = item.user ? item.user : item.user_guest;
                    item.ticket_info = item.ticket;
                    return item;
                });
                setTickets(updateTickets);
                setPageMeta(result.data.meta);
            }else {
                alert(result.message);
            }
            setModalLoader(false);
            toggleRefresh();
        })();
    }, [visible, firstShow, isRefresh, pageMeta, toggleRefresh, account, id]);

    const toggleTab = (val:generalObj) => {
        setTableHead((prevHead) => {
            return prevHead.filter((item:generalObj) => {
                if (item.id === val.id) {
                    item.active = true;
                }else {
                    item.active = false;
                }
                return item;
            })
        });

        setTickets((prevTickets) => {
            if (!prevTickets) return null;
            return prevTickets.filter((item:generalObj) => {
                if (val.id === 1 && item.used === 0) {
                    item.show = true;
                }else if (val.id === 2 && item.used === 1) {
                    item.show = true;
                }else {
                    item.show = false;
                }
                return item;
            })
        })
    }

    const updateSearch = (val:string) => {
        setSearchInput(val);
        setTickets((prevTickets) => {
            if (!prevTickets) return null;
            return prevTickets.filter((item:generalObj) => {
                let ticket_id=`7987${item.id}`;
                if (item.user.fullname.includes(val) || item.user.email.includes(val) || ticket_id.includes(val)) {
                    item.found = true;
                }else {
                    item.found = false;
                }
                return item;
            })
        })
    }

    const updateTicket = (val:generalObj) => {
        setTickets((prevTickets) => {
            if (!prevTickets) return null;
            return prevTickets.filter((item:generalObj) => {
                if (val.id === item.id) {
                    item.used = 1;
                    if (tableHead[1].active) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                }
                return item;
            })
        });
        setOpenAttendee(false);
        setAttendee(null);
    }

    const loadData = async () => {
        if (!account || !account.isAuthenticated || !id || !tickets) return;

        setModalLoader(true);
        let page = pageMeta && pageMeta.next_page ? pageMeta.next_page : 1;
        let result:generalObj = await fetchEventUserTickets(account.token, id, page, 100); 
        if (result.status) {
            let updateTickets = result.data.data.filter((item:generalObj) => {
                if (item.used === 0) {
                    item.show = true;
                }else {
                    item.show = false;
                }
                item.user = item.user ? item.user : item.user_guest;
                item.ticket_info = item.ticket;
                return item;
            });
            let new_tickets = [...tickets, ...updateTickets];
            setTickets(new_tickets);
            setPageMeta(result.data.meta);
        }else {
            alert(result.message);
        }
        setModalLoader(false);
    }

    return (
        <>
            {firstShow && (
                <>
                <div className={`check__in__modal modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}} ref={parentRef}>
                    <div className="modalContainer" style={{}}>
                        <div style={{width:'100%', height:'100%', position:'relative'}}>
                            <div style={{height:'100%', marginTop:"20px"}}>
                                <div style={{display:'flex', alignItems:'center', margin:"0px 20px"}}>
                                    <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                    <div className="sectionTitle" style={{fontSize:"13px", lineHeight:"16px"}}>Check In</div>
                                </div>
                                <div style={{display:"flex", alignItems:'center', flexDirection:'column', gap:'15px', height:"100%", marginTop:'10px'}}>
                                    <div style={{backgroundColor:'#353434', width:'100%', textAlign:'center', paddingBlock:'10px'}}>
                                        <div className='sectionTitle' style={{fontSize:'12px', fontWeight:700, textTransform:'capitalize'}}>{event.name}</div>
                                        <div>
                                            <div style={{color:'#9E9B9B', fontSize:'10px', fontWeight:'600'}}>Checked In</div>
                                            <div>{tickets ? tickets.filter((item:generalObj) => (item.used === 1)).length : 0}</div>
                                        </div>   
                                    </div>
                                    <button style={{textAlign:'center'}} onClick={() => openBarcode()}>
                                        <RedScannerSvg />
                                        <div className='postOwnerTextl' style={{margin:0, color:'#9E9B9B', fontSize:'12px', marginTop:'3px'}}>Tap to Scan Tickets</div>
                                    </button>
                                    <div className="bgMain" style={{marginBottom:"10px", marginTop:"12px", width:'100%', paddingBottom:"90px"}}>
                                        <div style={{display:'flex'}}>
                                            {tableHead.map((item:generalObj) => (
                                                <button key={item.id} style={{alignItems:'center', display:'flex', flexDirection:'column', width:'50%', borderTop:"1px solid rgba(255, 255, 255, 0.08)", padding:"12px 0px 0px"}} onClick={() => toggleTab(item)}>
                                                    <div className="itemOwner"  style={{color:item.active ? '#FFFFFF' : '#FFFFFF99', fontSize:'12px'}}>{item.title}</div>
                                                    {item.active && (<div style={{borderBottom:"1px solid #FFF", marginTop:"12px", width:"100%"}}></div>)}
                                                </button>
                                            ))}
                                        </div>
                                        <div style={{marginInline:'20px', marginTop:'20px'}}>
                                            <SearchBar inputProp={{placeholder:'Search Attendees or ticket ID', value:searchInput}} customInputText={{backgroundColor:'transparent'}} onTextChange={(event)=>updateSearch(event)} />
                                            <div style={{marginTop: '20px', overflowX:'hidden', height:`${parentRef.current ? parentRef.current.clientHeight - 250 : 250}px`}}> 
                                                <div style={{paddingBottom:'120px', display:'flex', flexDirection:'column', gap:'15px'}}>
                                                    {tickets && (
                                                        tickets.map((item:generalObj, index:number) => (
                                                            item.show && (item.found || item.found === undefined) && (
                                                                <button key={index} style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}} onClick={() => {setAttendee(item); setOpenAttendee(true)}}>
                                                                    <div style={{textAlign:'start'}}>
                                                                        <div className='postOwnerTextl' style={{marginLeft:0, textTransform:'capitalize'}}>{item.user.fullname}</div>
                                                                        <div className='postOwnerTextl' style={{marginLeft:0, marginTop:'3px', fontSize:'12px', fontWeight:'400', color:'#FFFFFF99'}}>{item.user.email}</div>
                                                                    </div>
                                                                    <div style={{textAlign:'start', width:'100%', marginTop:'5px', display:'flex', justifyContent:'space-between'}}>
                                                                        <div className='postOwnerTextl' style={{marginLeft:0, fontSize:'12px', fontWeight:'400', color:'#FFFFFF99'}}><span style={{color:"#FFF"}}>Type: </span><span style={{textTransform:'capitalize'}}>{item.ticket_info.category}</span> x{item.quantity} Ticket(s)</div>
                                                                        <div className='postOwnerTextl' style={{marginLeft:0, fontSize:'12px', fontWeight:'400', color:'#FFFFFF99'}}><span style={{color:"#FFF"}}>ID: </span>7987{item.id}</div>
                                                                    </div>
                                                                </button>
                                                            )
                                                        ))
                                                    )}
                                                    {pageMeta && pageMeta.next_page && (
                                                        <button style={{display:'flex', flexDirection:'column', alignItems:'center', backgroundColor:"#353434", color: "#FFFFFF99", textAlign:'center', padding:"10px"}} onClick={() => loadData()}>
                                                            Load more data
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            )}
            <ModalLoader visible={modalLoader} />
            {attendee && openAttendee && <AttendeeInfoModal visible={openAttendee} item={attendee} modalClose={() => setOpenAttendee(false)} onChecked={(val) => updateTicket(val)} /> }
        </>
    )
}

export default CheckInModal;