import { Link, useLocation } from "react-router-dom";
import { RootState } from "../redux";
import { useSelector } from "react-redux";
import { UrlType } from "../constant/objects_types";
import { SearchSvg } from "../styles/IconStyle";
import ProfileGenerator from "./ProfileGenerator";

const NavBar = ({ updateOpenSearchModal, barClass }:{
    updateOpenSearchModal: (val:boolean) => void ,
    barClass ?: string
}) => {
    const location = useLocation();
    const path_name = location.pathname.replace('/', "").split("/")[0];
    const account = useSelector((state:RootState) => state.account);

    return (
        <>
            <div style={{position:'relative'}} className={`navBar ${barClass}`}>
                <div>
                    <div>
                        <Link to={UrlType.HOME}>
                            <img src={require('../assets/logo.png')} alt={"logo"} className="logo" />
                        </Link>
                    </div>
                </div>
                <div className="desktop_nav_link">
                    <ul>
                        <li>
                            <Link to={UrlType.EXPLORE_LANDING} className={path_name === "explore" ? 'active' : ""}>Explore</Link>
                        </li>

                        <li>
                            <Link to={UrlType.CREATE_EVENT} className={path_name === "create_event" ? 'active' : ""}>Create Event</Link>
                        </li>

                        <li>
                            <Link to={UrlType.TICKET} className={path_name === "ticket" ? 'active' : ""}>My Tickets</Link>
                        </li>

                        <li>
                            <Link to={UrlType.CREATE_RESELL_EVENT} className={path_name === "create_resell_event" ? 'active' : ""}>Resell Tickets</Link>
                        </li>
                    </ul>
                </div>
                <div className="desktop_nav_profile">
                    <ul>
                        <li>
                            <Link to={"#"} onClick={() => updateOpenSearchModal(true)}>
                                <span>
                                    <SearchSvg />
                                </span>
                            </Link>
                        </li>

                        <li className="profile_nav_holder">
                            <Link to={account && account.isAuthenticated ? `${UrlType.PROFILE_USER}me` : `${UrlType.LOGIN}?redirect=${UrlType.PROFILE_USER}me`}>
                                <span>
                                    <ProfileGenerator constStyle={{width:'24px', height:'24px', borderRadius:'24px'}} textStyle={{fontSize:'13px', lineHeight:'16px'}} useAccount={true} />
                                </span>
                                <span>{account && account.isAuthenticated ? account.username : "Login/SignUp"}</span>
                            </Link>
                        </li>
                        {/* {showSearch && <li>
                            <a href="#1">
                                <span>
                                    <SearchSvg />
                                </span>
                            </a>
                        </li>}
                        <li>
                            <a href="#1">
                                <span>
                                    <ScanCodeSvg />
                                </span>
                            </a>
                        </li>
                        <li style={{position:'relative'}}>
                            <a href="#1">
                                <span>
                                    <NotificationSvg />
                                    { isNotification && <span style={{width:"5px", height:"5px", borderRadius:"5px", backgroundColor:'#DB012E', position:'absolute', right:"6px", top:"4px"}}></span> }
                                </span>
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default NavBar;