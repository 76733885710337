import { useState, useEffect } from "react";
import { checkEventPast, fetchEventUser, getDateStr, getEventDetail, getTimeStr, getUTCStr, processAddressnMap, setUpPrevUrl } from '../../action/generalFunc';
import { useSelector } from "react-redux";
import { UrlType, generalObj } from "../../constant/objects_types";
import { RootState } from "../../redux";
import ProfileGenerator from "../ProfileGenerator";
import { IoEllipsisVerticalSharp, IoEllipseSharp } from "react-icons/io5";
import ButtonFrame from "../ButtonFrame";
import { useNavigate, useOutletContext } from "react-router-dom";
import { SYMBOL } from "../../constant/constant";


const EventItem = ({ item, listCont, itemCont, imageCont, actionPressed, updateEventItem, openRecommendPop, is_searched=false }:{
    item: generalObj;
    listCont?: object;
    itemCont?: object;
    imageCont?: object;
    actionPressed: (action:string, id:number) => void;
    updateEventItem: (id:number) => void;
    openRecommendPop: () => void;
    is_searched?:boolean;
}) => {
    const [actionModal, setActionModal] = useState<boolean>(false);
    const account = useSelector((state:RootState) => state.account);
    const [eventOwner, setEventOwner] = useState<generalObj | null>(null);
    const [eventMinTicket, setEventMinTicket] = useState<number>(0);
    const { setTicketBuyAccount, setTicketId }:{ setTicketBuyAccount:Function, setTicketId:Function } = useOutletContext();
    const navigate = useNavigate();
    
    // const [popupAlert, setPopupAlert] = useState(false);
    
    console.log(actionModal);
    
    useEffect(() => {
        if (eventOwner || !item || !account) return;
        if (item.user) {
            setEventOwner(item.user);
        }else {
            const loadUser = async () => {
                const result:generalObj = await fetchEventUser(account.token, item.id);
                if (result.status && result.data.data.event.user) {
                    setEventOwner(result.data.data.event.user);
                }else {
                    setEventOwner(null);
                }
            }
            loadUser()
        }
    }, [account, item, eventOwner]);

    useEffect(() => {
        if (eventMinTicket || !item || !account) return;
        if (item.has_ticket) {
            if (item.tickets && item.tickets.length > 0) {
                let min_ticket:number = 9999999999999;
                item.tickets.forEach((element:generalObj) => {
                    if (min_ticket && (parseFloat(element.price) < min_ticket)) {
                        min_ticket = parseFloat(element.price);
                    }
                });
                setEventMinTicket(min_ticket);
            }else {
                const loadTicket = async () => {
                    const result:generalObj = await fetchEventUser(account.token, item.id);
                    if (result.status && result.data.data.event.has_ticket === 1 && result.data.data.event.tickets.length > 0) { 
                        let min_ticket:number = 9999999999999;
                        result.data.data.event.tickets.forEach((element:generalObj) => {
                            if (min_ticket && (parseFloat(element.price) < min_ticket)) {
                                min_ticket = parseFloat(element.price);
                            }
                        });
                        setEventMinTicket(min_ticket);
                    }else {
                        setEventMinTicket(0);
                    }
                }
                loadTicket();
            }
        }else {
            setEventMinTicket(0);
        }
    }, [account, item, eventMinTicket]);

    const assignTicketToPurchase = (ticket_id:number) => {
        setTicketBuyAccount(true); 
        setTicketId(ticket_id);
    }

    

    return (
        item && (item.recommended || item.recommended === undefined) ? (
            <>
                <div className="listContainer" style={{cursor:"pointer", ...listCont}}>
                    <div style={itemCont}>
                        <div className="displayImageContainer event__item" style={imageCont} onClick={()=> navigate(getEventDetail(item))}>
                            <div className="displayImage" style={{backgroundColor:'#c4c4c4'}}>
                                <img src={item.cover_image} className="displayImage" style={{backgroundColor:'#302929'}} alt="event item" />
                            </div>
                            {item.bought && 
                                <div className="paidLabel">
                                    <span style={{color: '#DB012E', fontWeight:'600'}}>Paid</span>
                                </div>
                            }
                            {checkEventPast(item) && 
                                <div className="paidLabel">
                                    <span style={{color: '#DB012E', fontWeight:'600'}}>Past event</span>
                                </div>
                            }

                            {item.visibility === 'private' && 
                                <div className="paidLabel" style={{borderTopRightRadius: "16px", borderBottomLeftRadius: "16px", borderTopLeftRadius:0, borderBottomRightRadius:0, bottom:'auto', top:'-1px'}}>
                                    <span style={{color: '#DB012E', fontWeight:'600'}}>Private event</span>
                                </div>
                            }
                        </div>
                        {eventOwner && (
                            <div className="sectionDisplay" style={{marginTop: 15}}>
                                <a href={"#userprofile"} onClick={() => account && account.isAuthenticated && (account.id === item.user_id ? navigate(`${UrlType.PROFILE_USER}me`) : navigate(`${UrlType.PROFILE_USER_ID}${item.user_id}/`))} style={{display:'flex', alignItems:'center', gap:'5px'}}>
                                    {eventOwner.profile_picture ? (
                                        <div className="profilePictureUser" style={{backgroundColor:'#c4c4c4'}}>
                                            <img src={eventOwner.profile_picture} className="profilePictureUser" alt="user profile" />
                                        </div>
                                    ): (
                                        <ProfileGenerator title={eventOwner.username?eventOwner.username[0].toUpperCase():"A"} constStyle={{marginBottom:"0px"}} textStyle={{fontSize:"13px", lineHeight:"16px"}} />
                                    )}
                                    <span className="itemOwner">{eventOwner.username}</span>
                                </a>
                                {account && item.user_id !== account.id && (
                                    <button onClick={()=>setActionModal(true)} style={{width:"32px", display:'flex', alignItems:'flex-end'}}>
                                        <IoEllipsisVerticalSharp size={"26px"} color="#FFF" />
                                    </button>
                                )}
                            </div>
                        )}
                        <div className="sectionDisplay">
                            <div onClick={()=> navigate(getEventDetail(item))} style={{marginTop: item.isOwner?"15px": "5px", maxWidth:account && item.user_id === account.id?'100%':'65%'}}>
                                <p className="itemOwner" style={is_searched ? {fontSize:14, marginTop:5} : {marginTop:10, fontSize:16}}>{item.name}</p>
        
                                <p className="itemOwner" style={{marginTop:10, fontSize:16}}>{eventMinTicket && eventMinTicket > 0 ?`${SYMBOL}${parseFloat(`${eventMinTicket}`).toLocaleString()}` :'Free'}</p>
        
                                <div style={{display:'flex', marginTop:"8px", alignItems:'center'}}>
                                    <span className="itemOwner" style={{fontWeight:"700", fontSize:"13px", color:"#9E9B9B"}}>{getDateStr(getUTCStr(item.start_date), true)}</span>
                                    <IoEllipseSharp size={"4px"} color="#9E9B9B" style={{margin:"0px 5px", top:"7px"}} />
                                    <span className="itemOwner" style={{fontWeight:"700", fontSize:"13px", color:"#9E9B9B"}}>{getTimeStr(getUTCStr(item.start_date), false)}</span>
                                </div>
                                <div>
                                    <p className="itemOwner" style={{fontWeight:"700", fontSize:"13px", color:"#9E9B9B", marginTop:'8px', width:"200px", whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>{item.location_type === 'venue' ? `${processAddressnMap(item.location, "addressLabel").slice(0, 40)}` : "Online"}.</p>
                                </div>
                            </div>
                            {!checkEventPast(item) && !item.bought && !is_searched && account && item.user_id !== account.id && item.has_ticket ? (
                                <div style={{display:"flex", alignItems:'flex-end', justifyContent:'flex-end'}}>
                                    <ButtonFrame title="Buy" contStyle={{
                                        backgroundColor:'#DB012E', 
                                        border:"none",
                                        width: 'auto',
                                        padding:"4px 18px",
                                        borderRadius:"8px"
                                    }} onPress={() => account && account.isAuthenticated ? navigate(setUpPrevUrl(window.location.pathname, getEventDetail(item, UrlType.BUY_TICKET))) : assignTicketToPurchase(item.id)}  />
                                </div>
                            ):null}
                        </div>
                    </div>
                </div>
                {/* <ActionModal visible={actionModal} itemValues={item} modalClose={()=>setActionModal(false)} actionPressed={(action, id) => {
                    actionPressed(action, id);
                    if (action === 'recommend') {
                        setActionModal(false);
                    }
                    if (action === 'save' && (item.saved === true || item.saved === undefined) ) {
                        setActionModal(false)
                        setPopupAlert(true)
                    }
                }} />
                
                <PopUpAlert visible={popupAlert} description={`Event has been ${item.saved === true ? 'saved' : item.saved === false ? 'unsaved' : 'saved'}`} modalClose={()=>setPopupAlert(false)} />  */}
            </>
        ): (
            <div className="listContainer" style={listCont}>
                <div style={itemCont}>
                    <div className="displayImageContainer" style={imageCont}>
                        <div className="displayImage" style={{backgroundColor:'transparent', border:"1px solid rgba(255, 255, 255, 0.1)", borderRadius:"12px", justifyContent:'center', alignItems:'center', padding:"0px 60px"}}>
                            <p className="sectionTitle" style={{fontSize:"13px", lineHeight:"16px", textAlign:'center'}}>We won’t recommend this event again.</p>
                            <button className="locationRegion" style={{fontSize:"14px", color:'#DB012E', lineHeight:"16px", marginTop:"8px"}}>
                                <span >Tap to undo</span>
                            </button>
                        </div>
                    </div>
                    <div className="displayImageContainer" style={{height:"auto", marginTop:"16px"}}>
                        <div className="displayImage" style={{backgroundColor:'transparent', height:'auto'}}>
                            <p className="locationRegion" style={{fontSize:"13px", color:'#9E9B9B', lineHeight:"16px"}}>This types of events would no longer be recommended to you. To make your experience better, <a href="#0" ><span style={{color:'#DB012E'}} onClick={() => openRecommendPop()}>Tap here to tell us why</span></a>.</p>
                        </div>
                        <div className="displayImage" style={{backgroundColor:'transparent', height:"85px"}}>
                            
                        </div>
                    </div>
                </div>
            </div>
        )
    )
}

export default EventItem;