import { IoChevronDownOutline, IoChevronForwardOutline } from "react-icons/io5";
import ProfileGenerator from "../ProfileGenerator";
import ListProfile from "./ListProfile";
import { generalObj } from "../../constant/objects_types";
import { useEffect, useState } from "react";
import ProfileEdit from "./ProfileEdit";
import ProfilePictureModal from "./ProfilePictureModal";
import BackArrow from "../BackArrow";

const ProfileEditList = ({ visible, profileInfo, modalClose }:{
    visible:boolean,
    profileInfo:generalObj,
    modalClose:()=>void,
}) => {
    const [profileData, setProfileData] = useState<generalObj>({
        fullname:{
            key:'fullname',
            title:'Name',
            value:profileInfo.name,
            valueNoSpace: false,
            descriptiveText:"Help people discover your account by using the name you’re known by. It could be your full name, business name or nickname.",
            props: {
                maxLength: 20
            },
            multiline: false
        },
        username:{
            key:'username',
            title:'Username',
            value:profileInfo.username,
            valueNoSpace: true,
            descriptiveText:"Input a new username.",
            props: {
                maxLength: 20
            },
            multiline: false
        },
        bio:{
            key:'bio',
            title:'Bio',
            valueNoSpace: false,
            value:profileInfo.bio || "",
            descriptiveText:"Tell people a little bit about yourself.",
            props: {
                maxLength: 150
            },
            multiline: true
        },
        links: false,
        website: {
            key:'website',
            title:'Website',
            valueNoSpace: true,
            value:profileInfo.socialLinks.website,
            descriptiveText:"Input the link to your website.",
            props: {
                keyboardType:'url'
            },
            multiline: false
        },
        instagram: {
            key:'instagram',
            title:'Instagram',
            valueNoSpace: true,
            prefixText:"https://instagram.com/",
            value:profileInfo.socialLinks.instagram || "https://instagram.com/",
            descriptiveText:"Input the username of your instagram.",
            props: {
                keyboardType:'url'
            },
            multiline: false
        },
        twitter: {
            key:'twitter',
            title:'Twitter',
            valueNoSpace: true,
            prefixText:"https://twitter.com/",
            value:profileInfo.socialLinks.twitter || "https://twitter.com/",
            descriptiveText:"Input the username of your twitter.",
            props: {
                keyboardType:'url'
            },
            multiline: false
        },
        youtube: {
            key:'youtube',
            title:'Youtube',
            valueNoSpace: true,
            value:profileInfo.socialLinks.youtube || "",
            descriptiveText:"Input the link to your youtube.",
            props: {
                keyboardType:'url'
            },
            multiline: false
        },
        snapchat: {
            key:'snapchat',
            title:'Snapchat',
            valueNoSpace: true,
            prefixText:"https://t.snapchat.com/",
            value:profileInfo.socialLinks.snapchat || "https://t.snapchat.com/",
            descriptiveText:"Input the username of your snapchat.",
            props: {
                keyboardType:'url'
            },
            multiline: false
        },
        tiktok: {
            key:'tiktok',
            title:'TikTok',
            valueNoSpace: true,
            prefixText:"https://www.tiktok.com/@",
            value:profileInfo.socialLinks.tiktok || "https://www.tiktok.com/@",
            descriptiveText:"Input the username of your tiktok.",
            props: {
                keyboardType:'url'
            },
            multiline: false
        },
    });
    const [openPPic, setOpenPPic] = useState<boolean>(false);

    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [editData, setEditData] = useState<generalObj | null>(null);
    const [firstShow, setFirstShow] = useState<boolean>(false);


    useEffect(() => {
        if (firstShow) return;
        if (!visible) return;
        setFirstShow(true);
    }, [visible, firstShow]);

    const updateProfileData = (pkey:string, value:any, valuekey:string="value") => {
        const Kdata = profileData[pkey]; 
        const updated_keyData = {...Kdata, [valuekey]: value};
        setProfileData({...profileData, [pkey]:updated_keyData});
        
        if (editData && editData.key === pkey) {
            setEditData(updated_keyData);
        }
    }

    const setDataForEdit = (key:string) => {
        setEditData(profileData[key]);
        setOpenEdit(true);
    }

    const clearEditData = () => {
        setOpenEdit(false);
        setTimeout(() => {
            setEditData(null);
        }, 1000);
    }
    return (
        <>
            {firstShow && (
                <>
                <div className={`edit__profile__list modalParentContainer${visible ? " animate__animated animate__slideInRight" :  " animate__animated animate__slideOutRight"}`} style={{backgroundColor:"#0D0404"}}>
                    <div className="modalContainer" style={{}}>
                        <div style={{margin:"0px 20px", width:'100%', height:'100%'}}>
                            <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"30px"}}>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <BackArrow onPress={modalClose} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                                    <div className="sectionTitle" style={{fontSize:"14px", lineHeight:"16px"}}>Edit Profile</div>
                                </div>
                            </div>

                            <div style={{display:'flex', justifyContent:'center', width:'100%', marginTop:"20px", alignItems:'center'}}>
                                <button style={{alignItems:'center', justifyContent:'center', flexDirection:'column', display:"flex", gap:"6px"}} onClick={() => setOpenPPic(true)}>
                                    <ProfileGenerator constStyle={{ width:"48px", height:"48px", borderRadius:"48px"}} useAccount={true} />
                                    <div className="sectionTopLink" style={{fontWeight:'500'}}>Change Profile</div>
                                </button>
                            </div>

                            
                            <div style={{width:'100%', marginTop:"40px", height:"100%"}}>
                                <div style={{height:"calc(100% - 270px)", paddingBottom:'90px', overflowX:'hidden'}}>
                                    <ListProfile label={'Name'} value={profileData.fullname.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} onPress={()=>setDataForEdit(profileData.fullname.key)} />
                                    <ListProfile label={'Username'} value={profileData.username.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:"32px"}} onPress={()=>setDataForEdit(profileData.username.key)} />
                                    <ListProfile label={'Bio'} value={profileData.bio.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:"32px"}} inputStyle={{height:'auto', maxLength:"100px", paddingBottom:"16px"}} onPress={()=>setDataForEdit(profileData.bio.key)} />
                                    {!profileData.links && (<ListProfile label={'Website'} value={profileData.website.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:"32px"}} onPress={()=>setDataForEdit(profileData.website.key)} />)}

                                    <button className="inputField" style={{textAlign:'left', marginTop:"32px"}} onClick={()=>setProfileData({...profileData, links:!profileData.links})}>
                                        <span className="inputLabel" style={{fontWeight:'500', fontSize:"14px", lineHeight:"19px", color:'rgba(255, 255, 255, 0.3)'}}>LINKS</span>
                                        <div className="inputTextCont" style={{flexDirection:'column'}}>
                                            <div className="inputText" style={{padding:"0px",backgroundColor: 'transparent', display:'flex', justifyContent:'space-between'}}>
                                                <span className="inputLabel" style={{fontWeight:'500', fontSize:"14px", lineHeight:"19px", color:'#FFF', width:'100%'}}>Adding your social media links would allow other people connect with you on those platforms.</span>
                                                {profileData.links ? (
                                                    <IoChevronDownOutline size={"24px"} color="rgba(255, 255, 255, 1)" />   
                                                ):(
                                                    <IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />
                                                )}
                                            </div>
                                            
                                        </div>
                                    </button>

                                    {profileData.links && (
                                        <div style={{marginTop:"32px"}}>
                                            <ListProfile label={'Instagram'} value={profileData.instagram.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} onPress={()=>setDataForEdit(profileData.instagram.key)} />
                                            <ListProfile label={'Twitter'} value={profileData.twitter.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:"32px"}} onPress={()=>setDataForEdit(profileData.twitter.key)} />
                                            <ListProfile label={'Website'} value={profileData.website.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:"32px"}} onPress={()=>setDataForEdit(profileData.website.key)} />
                                            <ListProfile label={'Youtube'} value={profileData.youtube.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:"32px"}} onPress={()=>setDataForEdit(profileData.youtube.key)} />
                                            <ListProfile label={'Snapchat'} value={profileData.snapchat.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:"32px"}} onPress={()=>setDataForEdit(profileData.snapchat.key)} />
                                            <ListProfile label={'TikTok'} value={profileData.tiktok.value} iconText={<IoChevronForwardOutline size={"24px"} color="rgba(255, 255, 255, 1)" />} fieldStyle={{marginTop:"32px"}} onPress={()=>setDataForEdit(profileData.tiktok.key)} />
                                        </div>
                                    )}
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                {editData && (<ProfileEdit 
                    visible={openEdit} 
                    item={editData} 
                    onTextChange={(val)=>{
                        updateProfileData(editData.key, val);
                        clearEditData();
                    }} 
                    modalClose={clearEditData} 
                />)}
                <ProfilePictureModal visible={openPPic} modalClose={()=>setOpenPPic(false)} />
            </>
            )}
            
        </>
    )
}

export default ProfileEditList;