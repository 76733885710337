import { useState, useEffect } from "react";
import { BackArrow, EventItem, ProfileGenerator } from "../../components";
import { UrlType, generalObj } from "../../constant/objects_types";
import { InstagramSvg, TwitterSvg, WebsiteSvg, YoutubeSvg, TikTokSvg, SnapchatSvg } from "../../styles/IconStyle";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchEventByUserId, fetchUser } from "../../action/generalFunc";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const ProfileUser = () => {
    const [profileLinks, setProfileLinks] = useState<generalObj[]>([
        {
            id:'1',
            name:"Website",
            slug: "website",
            url:null,
            icon: <WebsiteSvg />,
            show: false
        },{
            id:'2',
            name:"Instagram",
            slug: "instagram",
            url:null,
            icon:<InstagramSvg />,
            show: false
        },{
            id:'3',
            name:"Twitter",
            slug: "twitter",
            url:null,
            icon: <TwitterSvg />,
            show: false
        },{
            id:'4',
            name:"Youtube",
            slug: "youtube",
            url:null,
            icon: <YoutubeSvg />,
            show: false
        },{
            id:'5',
            name:"Tiktok",
            slug: "tiktok",
            url:null,
            icon: <TikTokSvg />,
            show: false
        },{
            id:'6',
            name:"Snapchat",
            slug: "snapchat",
            url:null,
            icon: <SnapchatSvg />,
            show: false
        }
    ]);
    const [profileInfo, setProfileInfo] = useState<generalObj>({
        name: '',
        username: '',
        bio: "",
        email: ''
    });
    const [events, setEvents] = useState<generalObj[] | null>(null);
    const [showMore, setShowMore] = useState<boolean>(true);
    const [isSticky, setIsSticky] = useState<boolean>(false);
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();
    const { parentRef, setModalLoading }:{ parentRef:generalObj, setModalLoading:Function } = useOutletContext();
    
    const [getEventOffset, setEventOffset] = useState<number | null>();
    const { id } = useParams();
    
    useEffect(() => {
        if (!account || !account.isAuthenticated || !id) {
            navigate(UrlType.HOME);
            return;
        }
        setModalLoading(true);
        const getMyEvent = async () => {
            const myevt:generalObj = await fetchEventByUserId(account.token, id);
            if (myevt.status) {
                setEvents(myevt.data.data);
            }
        }

        (async () => {
            let result:generalObj = await fetchUser(account.token, id);
            if (result.status) {
                let user_data = result.data.data.user;
                setProfileInfo({
                    name: user_data.fullname,
                    username: user_data.username,
                    gender: user_data.gender,
                    phone: user_data.phone,
                    email: user_data.email,
                    bio: user_data.bio,
                    country: user_data.country,
                    dob: user_data.dob,
                    profileImage:user_data.profile_picture
                });
                
                setProfileLinks((prevProfileLinks) => {
                    return (
                        prevProfileLinks.filter((item:generalObj) => {
                            if (user_data[item.slug]) {
                                item.url = user_data[item.slug];
                                item.show = true;
                            }else {
                                item.show = false;
                            }
                            return item;
                        })
                    )
                })
                getMyEvent();
            }else {
                alert(result.message);
                navigate(UrlType.HOME);
            }
            setModalLoading(false);
        })();
    }, [account, id, navigate, setModalLoading]);

    const handleScrolling = () => {
        if (window.innerWidth > 768) return;
        if (getEventOffset && parentRef.current.scrollTop > getEventOffset) {
            setIsSticky(true);
        }else {
            setIsSticky(false);
        }
    }

    useEffect(() => {
        if (!parentRef) return;
        const parent_ref = parentRef.current;
        parent_ref.addEventListener('scroll', handleScrolling);

        return () => parent_ref.removeEventListener('scroll', handleScrolling);
    });

    return (
        <>  
            <div className="profileView__container" style={{height:'100%'}}>
                <div style={{padding:"0px 20px"}}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'100%', marginTop:"20px"}}>
                        <div style={{flexDirection:'row', alignItems:'center', display:"flex"}} onClick={() => window.history.back()}>
                            <BackArrow onPress={() => window.history.back()} arrowStyle={{position:'relative', left:'0', top:"0px", width:'18px'}} arrowBtnStyle={{position:"relative", left:'-6px', top:"2px"}} />
                            <span className="sectionTitle">{profileInfo.username ? profileInfo.username : 'Guest'}</span>
                        </div>
                    </div>

                    <div style={{display:'flex', justifyContent:'space-between', width:'100%', marginTop:"20px", alignItems:'center'}}>
                        <div style={{display:'flex', alignItems:'center'}}>
                            <button style={{position:'relative'}}>
                                {profileInfo.profileImage ? (
                                    <img src={profileInfo.profileImage} style={{ width:"48px", height:"48px", borderRadius:"48px"}} alt="profile" />    
                                ): (
                                    <ProfileGenerator title={profileInfo.username ? profileInfo.username[0].toUpperCase() : 'A'} constStyle={{ width:"48px", height:"48px", borderRadius:"48px"}} useAccount={false} />
                                )}
                            </button>
                            <div style={{marginLeft:"8px"}}>
                                <div className="postOwnerText" style={{marginLeft:"0px", fontSize:"13px", lineHeight:"16px"}}>{profileInfo.name}</div>
                                <div className="inputLabel" style={{color:'rgba(255, 255, 255, 0.6)', fontWeight:'500', marginTop:"6px"}}>@{profileInfo.username}</div>
                            </div>
                        </div>
                        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                            <div className="postOwnerText" style={{marginLeft:"0px", fontSize:"18px", lineHeight:"25px", fontWeight:'500'}}>{events ? events.length : 0}</div>
                            <div className="inputLabel" style={{color:'rgba(255, 255, 255, 0.6)', fontWeight:'500', marginTop:"3px"}}>Events</div>
                        </div>
                    </div>

                    {/* Description */}
                    {profileInfo.bio && (
                        <>
                            <div style={{marginTop:"24px"}}>
                                <p className="locationRegion" style={{marginTop:"5px", fontSize:"14px", lineHeight:"19px", fontWeight:'400'}}>
                                    {profileInfo.bio.length >= 100 ? (
                                        showMore ? `${profileInfo.bio.slice(0, 100)}...` : profileInfo.bio
                                    ): profileInfo.bio }
                                </p>
                            </div>

                            {/* Truncate Bio */}
                            {profileInfo.bio.length >= 100 && (
                                <div style={{marginTop:"24px", display:'flex', width:'100%', justifyContent:'center'}}>
                                    <button onClick={()=>setShowMore(!showMore)}>
                                        <span className="buttonFrameText" style={{fontSize:"12px", lineHeight:"16px", color:'rgba(255, 255, 255, 0.6)'}}>{showMore ? "SHOW MORE" : "SHOW LESS"}</span>
                                    </button>
                                </div>
                            )}
                        </>
                    )}

                    {/* Profile Events links */}
                    <div style={{margin:"24px 0px", display:'flex', gap:"32px", overflowY:'hidden'}}>
                        {profileLinks.map((item:generalObj, index:number) => (
                            <a key={index} rel={"noreferrer"} target={item.show && item.url ? "_blank":"_self"} href={item.show && item.url ? item.url:"#0"} style={{alignItems:'center', display:'flex', flexDirection:'column', justifyContent:'center', opacity:item.show && item.url ? "1" : "0.3"}}>
                                <div style={{width:32, height:32, borderRadius:32, backgroundColor:'#191818', justifyContent:'center', alignItems:'center', display:'flex'}}>
                                    {item.icon}
                                </div>
                                <div className="locationRegion" style={{fontSize:"10px", lineHeight:"14px", color:'rgba(255, 255, 255, 0.6)', marginTop:"2px"}}>{item.name}</div>
                            </a>
                        ))}
                    </div>
                </div>

                {/* Event list */}
                <div className="bgMain my_event" style={{marginBottom:"10px", marginTop:"12px", width:'100%', paddingBottom:"90px"}}>
                    <div className={`${isSticky? "sticky":""}`} ref={(e) => {e && e.offsetTop > 0 && setEventOffset(e.offsetTop);}} style={{alignItems:'center', display:'flex', flexDirection:'column', borderTop:"1px solid rgba(255, 255, 255, 0.08)", padding:"12px 20px 0px"}}>
                        <div className="itemOwner">Events</div>
                        <div style={{borderBottom:"1px solid #FFF", marginTop:"12px", width:"100%"}}></div>
                    </div>
                    <div className="event_list__container">
                        {events && (
                            events.map((item:generalObj, index:number) => (
                                <EventItem key={index} item={item} listCont={{marginTop: index === 0 ? "20px" : "40px"}} imageCont={{width:`${parentRef.current.clientWidth - 40}px`, height:`${parentRef.current.clientWidth / (4/3)}px`}} actionPressed={(action, id)=>console.log(action, id)} updateEventItem={(id)=>console.log(id)} openRecommendPop={() => console.log('open recommend')} />
                            ))
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfileUser;