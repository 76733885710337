import { Link } from "react-router-dom";
import { UrlType } from "../constant/objects_types";
import { FaInstagram } from "react-icons/fa6";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import HomeDivider from "./home/HomeDivider";
import { useState } from "react";

const Footer = () => {
    const [iGColor, setIgColor] = useState('#FFF');
    const [mailColor, setMailColor] = useState('#FFF');

    return (
        <>
            <div className="footer_main">
                <HomeDivider />
                <div className="footer_body flex items-center py-6 justify-between w-full">
                    <div className="footer_left">
                        <img src={require('../assets/logo.png')} alt="logo" />
                        <div>
                            <Link to={UrlType.EXPLORE_LANDING}>
                                Explore
                            </Link>
                            <Link to={UrlType.EXPLORE_LANDING}>
                                Help
                            </Link>
                        </div>
                    </div>
                    <div className="footer_right">
                        <a href="https://www.instagram.com/ticki.co" target="_blank" rel="noopener noreferrer">
                            <FaInstagram color={iGColor} onMouseOver={() => setIgColor('#DB012E')} onMouseOut={() => setIgColor('#FFF')} size={20} aria-label="Instagram" />
                        </a>      
                        <a href="mailto:ticki.information@gmail.com">
                            <MdOutlineMailOutline color={mailColor} onMouseOver={() => setMailColor('#DB012E')} onMouseOut={() => setMailColor('#FFF')} size={20} aria-label="Email" />
                        </a>  
                        <p className="">
                            <FaPhoneAlt size={16}/> -  0814 412 2323</p>
                    </div>
                </div>
                
            </div>
        </>
    )
}

export default Footer;